import Rating from "@mui/material/Rating";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/navigation";
import { Navigation } from "swiper/modules";
import { Scrollbar } from "swiper/modules";
import { dataReviewsSlider } from "./constants/dataReviewsSlider";
import { NextBtn, PrevBtn } from "../../../assets/svg/sliderButtons";
import { useRef, useState } from "react";

const ReviewsSlider = () => {
  const swiperRef = useRef(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const handlePrevClick = () => {
    if (swiperRef && swiperRef.current) {
      swiperRef.current.swiper.slidePrev();
    }
  };
  const handleNextClick = () => {
    if (swiperRef && swiperRef.current) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handleObserverUpdate = () => {
    if (swiperRef && swiperRef.current) {
      const swiperInstance = swiperRef.current.swiper;
      setCurrentSlideIndex(swiperInstance.activeIndex);
    }
  };

  return (
    <section className="reviewsSlider">
      <h1 className="reviewsSlider__title">ОТЗЫВЫ</h1>
      <Swiper
        className="sliderDesktop"
        slidesPerView={1}
        // style={{ overflowX: "unset" }}
        navigation={{
          prevEl: ".custom-prev-button",
          nextEl: ".custom-next-button",
        }}
        observer={true}
        onSlideChange={handleObserverUpdate}
        modules={[Navigation]}
        ref={swiperRef}
      >
        {dataReviewsSlider.map((slider, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="reviewsSlider__wrapperItems">
                {slider.map((item, index) => {
                  return (
                    <div className="item" key={index}>
                      {item.avatar}
                      <Rating value={item.rating} readOnly />
                      <h4 className="name">{item.name}</h4>
                      <p className="text">{item.text}</p>
                    </div>
                  );
                })}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <div className="customButtons">
        <div
          className={`custom-button-prev ${
            currentSlideIndex === 0 ? "last" : ""
          }`}
          onClick={handlePrevClick}
        >
          <PrevBtn />
        </div>
        <div
          className={`custom-button-next ${
            currentSlideIndex === dataReviewsSlider.length - 1 ? "last" : ""
          }`}
          onClick={handleNextClick}
        >
          <NextBtn />
        </div>
      </div>
      <Swiper
        className="sliderTablet"
        slidesPerView={1}
        // style={{ overflowX: "unset" }}
        scrollbar={{
          hide: false,
          // draggable: true,
        }}
        modules={[Scrollbar]}
      >
        {dataReviewsSlider.map((slider, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="reviewsSlider__wrapperItems">
                {slider.map((item, index) => {
                  return (
                    <div className="item" key={index}>
                      {item.avatar}
                      <Rating value={item.rating} readOnly />
                      <h4 className="name">{item.name}</h4>
                      <p className="text">{item.text}</p>
                    </div>
                  );
                })}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Swiper
        className="sliderMobile"
        style={{ overflowY: "unset" }}
        spaceBetween={20}
        slidesPerView={1.3}
        scrollbar={{
          hide: false,
          // draggable: true,
        }}
        modules={[Scrollbar]}
      >
        {dataReviewsSlider.map((slider) => {
          return (
            <>
              {slider.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="reviewsSlider__wrapperItems">
                      <div className="item" key={index}>
                        {item.avatar}
                        <Rating value={item.rating} readOnly />
                        <h4 className="name">{item.name}</h4>
                        <p className="text">{item.text}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </>
          );
        })}
      </Swiper>
    </section>
  );
};
export default ReviewsSlider;
