import { dataTeam } from "./constants/dataTeam";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper/modules";
import "swiper/scss";
import "swiper/scss/scrollbar";

const Team = () => {
  return (
    <section className="team">
      <h1 className="team__title">КОМАНДА</h1>
      <p className="team__subtitle">
        REMONOUT – профи своего дела, у нас работают те, кто знает
        о ноутбуках всё{" "}
      </p>
      <div className="team__wrapperItems">
        {dataTeam.map((item, index) => {
          return (
            <div className="item" key={index}>
              <div className="wrapperName">
                <p className="name">{item.name}</p>
                <div className="photo">{item.photo}</div>
              </div>
              <p className="text">{item.text}</p>
            </div>
          );
        })}
      </div>
      <div className="mobileTeam">
        <Swiper
          // style={{ overflowY: "unset" }}
          // spaceBetween={20}

          centeredSlides={true}
          slidesPerView={1}
          scrollbar={{
            hide: false,
            // draggable: true,
          }}
          modules={[Scrollbar]}
          // className="mySwiper"
        >
          {dataTeam.map((item, index) => {
            return (
              <SwiperSlide centeredSlides={true}>
                <div className="item">
                  <div className="wrapperName">
                    <p className="name">{item.name}</p>
                    <div className="photo">{item.photo}</div>
                  </div>
                  <p className="text">{item.text}</p>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
};
export default Team;
