import { Link } from "react-router-dom";
import Rating from "@mui/material/Rating";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper/modules";
import "swiper/scss";
import "swiper/scss/scrollbar";
import { dataReviews } from "./constants/dataReviews";

import Avatar from "@mui/material/Avatar";

const Reviews = () => {
  return (
    <section className="reviews">
      <h1 className="reviews__title">ОТЗЫВЫ</h1>
      <p className="reviews__text">
        К нам дважды с одной проблемой не приходят — но к нам возвращаются:
        мы не только ремонтируем, мы ещё и улучшаем. Наши клиенты тепло
        отзываются о нас — а мы их очень ценим и всегда рады видеть снова.
        <Link className="link" to="/feedback">
          {" "}
          Смотреть отзывы
        </Link>
      </p>
      <div className="reviews__wrapperItems desktop">
        {dataReviews.map((item, index) => {
          return (
            <div className="item" key={index}>
              {/* {item.avatar} */}
              <Avatar
                className="avatar"
                src={item.avatar}
                alt="avatar"
                sx={{ width: 150, height: 150 }}
              ></Avatar>
              <Rating value={item.rating} readOnly />
              <h4 className="name">{item.name}</h4>
              <p className="text">{item.text}</p>
            </div>
          );
        })}
      </div>

      <Swiper
        className="slider"
        spaceBetween={20}
        slidesPerView={1.3}
        // centeredSlides={true}
        style={{ overflowY: "unset" }}
        scrollbar={{
          hide: false,
          // draggable: true,
        }}
        modules={[Scrollbar]}
      >
        {dataReviews.map((item, index) => {
          return (
            <SwiperSlide>
              <div className="reviews__wrapperItems mobile">
                <div className="item" key={index}>
                  {/* {item.avatar} */}
                  <Avatar
                    className="avatar"
                    src={item.avatar}
                    alt="avatar"
                    sx={{ width: 150, height: 150 }}
                  ></Avatar>
                  <Rating value={item.rating} readOnly />
                  <h4 className="name">{item.name}</h4>
                  <p className="text">{item.text}</p>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};
export default Reviews;
