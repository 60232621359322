import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { dataPrice } from "./constants/dataPrice";
import OrderService from "../../ModalWindows/OrderService";

const PriceTableMobile = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const styleBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  return (
    <div className="priceTableMobile">
      {dataPrice.map((item, index) => {
        return (
          <TableContainer
            className="tableMobile"
            component={Paper}
            key={index}
            sx={{ boxShadow: "none" }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="head" align="left" colSpan={2}>
                    {item.name}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    className="itemTable"
                    align="left"
                    variant="string"
                  >
                    Время
                  </TableCell>
                  <TableCell className="itemTable" align="left">
                    {item.time}
                  </TableCell>
                </TableRow>
                <TableRow className="itemTable">
                  <TableCell className="itemTable" align="left">
                    Гарантии
                  </TableCell>
                  <TableCell className="itemTable" align="left">
                    {item.guarantees}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="itemTable" align="left">
                    Стоимость
                  </TableCell>
                  <TableCell className="itemTable right" align="left">
                    {item.price}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    className="itemTable"
                    align="center"
                    colSpan={2}
                    sx={{ maxWidth: "294px", width: "100%" }}
                  >
                    <Button
                      className="orderButton"
                      variant="contained"
                      onClick={handleOpen}
                    >
                      Заказать
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        );
      })}
      <Modal open={open} onClose={handleClose}>
        <Box sx={styleBox}>
          <OrderService handleClose={handleClose} />
        </Box>
      </Modal>
    </div>
  );
};
export default PriceTableMobile;
