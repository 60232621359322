import { Placemark, Map, YMaps, ZoomControl } from "@pbe/react-yandex-maps";
import { coordinates } from "./constants/coordinates";
import { API_KEY_MAP } from "./constants/api";

const MapYandex = () => {
  return (
    <YMaps
      query={{
        apikey: API_KEY_MAP,
        lang: "ru_RU",
      }}
    >
      <div className="map">
        <Map
          defaultState={{ center: coordinates, zoom: 16 }}
          width="100%"
          height="100%"
        >
          <Placemark geometry={coordinates} />
          <ZoomControl options={{ float: "right" }} />
        </Map>
      </div>
    </YMaps>
  );
};
export default MapYandex;
