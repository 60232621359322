import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper/modules";
import "swiper/scss";
import "swiper/scss/scrollbar";
import {
  AppleIcon,
  AsusIcon,
  DellIcon,
  LenovoIcon,
  SamsungIcon,
  VaioIcon,
} from "../../../assets/svg/brandsIcons";
import { useEffect, useState } from "react";

const Brands = () => {
  const [slidesPerView, setSlidesPerView] = useState(6);

  useEffect(() => {
    const handleResize = () => {
      // Определите условия, при которых нужно изменять slidesPerView

      if (window.innerWidth <= 768) {
        setSlidesPerView(3);
        if (window.innerWidth <= 390) {
          setSlidesPerView(2);
        }
      }
    };

    // Добавьте обработчик события изменения размера окна при монтировании компонента
    window.addEventListener("resize", handleResize);

    // Уберите обработчик события при размонтировании компонента
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section className="brands">
      <h1 className="brands__title">МЫ РАБОТАЕМ С БРЕНДАМИ</h1>
      <div className="slider-container">
        <Swiper
          spaceBetween={20}
          slidesPerView={slidesPerView}
          scrollbar={{
            hide: false,
            draggable: true,
          }}
          modules={[Scrollbar]}
        >
          <SwiperSlide>
            <AsusIcon />
          </SwiperSlide>
          <SwiperSlide>
            <LenovoIcon />
          </SwiperSlide>
          <SwiperSlide>
            <SamsungIcon />
          </SwiperSlide>
          <SwiperSlide>
            <VaioIcon />
          </SwiperSlide>
          <SwiperSlide>
            <DellIcon />
          </SwiperSlide>
          <SwiperSlide>
            <AppleIcon />
          </SwiperSlide>
        </Swiper>
      </div>
      {/* <div className="slider-container mobile">
        <Swiper
          slidesPerView={2}
          scrollbar={{
            hide: true,
            draggable: true,
          }}
          modules={[Scrollbar]}
          // className="mySwiper"
        >
          <SwiperSlide>
            <AsusIcon />
          </SwiperSlide>
          <SwiperSlide>
            <LenovoIcon />
          </SwiperSlide>
          <SwiperSlide>
            <SamsungIcon />
          </SwiperSlide>
          <SwiperSlide>
            <VaioIcon />
          </SwiperSlide>
          <SwiperSlide>
            <DellIcon />
          </SwiperSlide>
          <SwiperSlide>
            <AppleIcon />
          </SwiperSlide>
        </Swiper>
      </div> */}
    </section>
  );
};

export default Brands;
