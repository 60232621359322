import Footer from "../Footer";
import Header from "../Header";
import ContactsWithUs from "./ContactsWithUs";
import MapYandex from "./MapYandex";

const ContactsPage = () => {
  return (
    <>
      <Header isMainPage={false} />
      <div className="wrapperContactsPage">
        <ContactsWithUs />
        <MapYandex />
      </div>
      <Footer />
    </>
  );
};

export default ContactsPage;
