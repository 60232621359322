export const dataAccordion = [
  {
    title: "Диагностика",
    text: "Бесплатно. В Remonout мы используем технологии и методы, которые позволяют нам быстро и точно определить неполадки вашего ноутбука. Это позволяет приступить к работе чётко понимая, что нужно для решения ситуации.",
  },
  {
    title: "Перегрев",
    text: "Проблема распространённая, встречается у многих. Мы найдём, что конкретно заставляет Ваш ноутбук нагреваться и устраним проблему в кратчайшие сроки.",
  },
  {
    title: "Восстановление данных",
    text: "Если Ваши данные можно спасти – мы это сделаем. Знания наших сотрудников и специализированные программы помогут Вам в трудной ситуации.",
  },
  {
    title: "Очистка ноутбука",
    text: "Медленная работа, мелкие сбои, опасность поломки компонентов и даже произвольная перезагрузка – вот лишь крохотная часть того что может вызывать в ноутбуке элементарная пыль. Ноутбуки - устройства компактные и при этом они все имеют воздушное охлаждение. По этой причине пыль неизбежно скапливается внутри. Регулярная очистка от пыли необходима.",
  },
  {
    title: "Ремонт клавиатуры",
    text: "Клавиатура – самая подвижная часть ноутбука, именно поэтому больше всего остального подвержена износу. Клавиши затираются, попадает пыль, иногда – крошки. Покупать новый ноутбук из-за проблем с клавиатурой – абсурд. После нашего ремонта Ваша клавиатура будет как новая.",
  },
  {
    title: "Модернизация ноутбука",
    text: "Распространённое заблуждение: многие считают, что модернизация удел компьютеров стационарных. Но Ваш ноутбук можно улучшить, в некоторых случаях – очень существенно. Мы модернизируем Ваш ноутбук фирменными деталями от производителей, чтобы избежать любых конфликтов оборудования или его неэффективности. Возможна модернизация любого компонента, в том числе и материнской платы. Цена зависит от конкретной конфигурации.",
  },
  {
    title: "Установка системы и программ",
    text: "Компоненты оборудования – не единственный источник проблемы. Неправильная работа системы или программного обеспечения может очень сильно влиять на работу ноутбука. Опыт наших специалистов позволит подобрать для Вас самую эффективную палитру программ под Ваши личные цели и вкусы.",
  },
  {
    title: "Профилактика",
    text: "Чтобы не пришлось лечиться люди проходят регулярное обследование. Так и ноутбуку полезна профилактика. Мы проведём ряд тестов и выясним где может возникнуть проблема до того как она там нарисуется. И предупредим её заранее.",
  },
];
