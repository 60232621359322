import {
  Battary,
  Cleaning,
  Cooler,
  DataRecovery,
  Diagnostic,
  Modernization,
  Prevention,
  Keyboard,
  SystemInstallation,
  UrgentRepair,
} from "../../../../assets/svg/professionalismSvg";

export const dataProfessionalism = [
  {
    svg: <Diagnostic />,
    title: "Полная диагностика",
    text: "мы найдём любую неисправность — бесплатно",
  },
  {
    svg: <DataRecovery />,
    title: "Восстановление данных",
    text: "если их можно спасти — мы сделаем",
  },
  {
    svg: <Modernization />,
    title: "Модернизация ноутбука",
    text: "мы найдём фирменные детали для вашей техники",
  },
  {
    svg: <UrgentRepair />,
    title: "Срочный ремонт",
    text: "от одного часа, мы ценим ваше время",
  },
  {
    svg: <Cleaning />,
    title: "Очистка ноутбука",
    text: "пыль может очень мешать, особенно в ноутбуке",
  },
  {
    svg: <Battary />,
    title: "Замена блоков ноутбука",
    text: "клавиатура, дисплей, материнская плата",
  },
  {
    svg: <Cooler />,
    title: "Ноутбук греется",
    text: "мы выясним и устраним причину",
  },
  {
    svg: <Keyboard />,
    title: "Ремонт клавиатуры",
    text: "она ломается чаще всего остального",
  },
  {
    svg: <SystemInstallation />,
    title: "Установка системы и программ",
    text: "ваша система будет безупречной",
  },
  {
    svg: <Prevention />,
    title: "Мы также выполняем профилактику ноутбука",
    text: "чтобы ремонт не был нужен",
  },
];
