import Footer from "../Footer";
import Header from "../Header";
import PrivacyPolicy from "./PrivacyPolicy";

const PrivacyPolicyPage = () => {
  return (
    <>
      <Header isMainPage={false} />
      <PrivacyPolicy />
      <Footer />
    </>
  );
};

export default PrivacyPolicyPage;
