export const Cross = () => {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 24.5781L24 6.57812"
        stroke="#4B67B9"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M23.918 24.6564L6.07573 6.49998"
        stroke="#4B67B9"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};
