import { dataWorkProcess } from "./constants/dataWorkProcess";
import laptop from "../../../assets/img/workProcessLaptop.png";

const WorkProcess = () => {
  return (
    <section className="workProcess">
      <h1 className="workProcess__title">КАК МЫ РАБОТАЕМ</h1>
      <div className="workProcess__wrapperContent">
        <div className="workProcess__wrapperImg">
          <img className="workProcess__img" src={laptop} alt="laptop"></img>
        </div>

        <div className="workProcess__wrapperItems">
          {dataWorkProcess.map((item, index) => {
            const isLastItem = index === dataWorkProcess.length - 1;
            return (
              <div className="item" key={item.number}>
                <div className="wrapperCircle">
                  <div className="circle">
                    <p className="number">{item.number}</p>
                  </div>
                  {!isLastItem && <div className="line"></div>}
                </div>
                <div className="wrapperText">
                  <h3 className="title">{item.title}</h3>
                  <p className="text">{item.text}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
export default WorkProcess;
