import { data } from "./constants/data";
import handWithLaptop from "../../../assets/img/handWithLaptop.jpg";

const Advantages = () => {
  return (
    <section className="advantages">
      <img
        className="advantages__img"
        src={handWithLaptop}
        alt="hand with laptop"
      ></img>
      <div className="advantages__container">
        <h1 className="advantages__title">НАШИ ПРЕИМУЩЕСТВА</h1>
        <div className="advantages__wrapperItems">
          {data.map((item, index) => {
            return (
              <div className="advantages__item" key={index}>
                <div className="img">{item.img}</div>
                <h3 className="title">{item.title}</h3>
                <p className="text">{item.text}</p>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
export default Advantages;
