import { Button } from "@mui/material";
import Rating from "@mui/material/Rating";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import laptopImg from "../../../assets/img/leaveFeedbackLaptop.jpg";
import { useState } from "react";
import * as Yup from "yup";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Formik, Form, Field, ErrorMessage } from "formik";
import sendEmail from "../../ModalWindows/sendEmail ";
import ReviewsSent from "../../ModalWindows/ReviewSent";
import { IconQuestion } from "../../../assets/svg/iconsInInput";

const LeaveFeedback = () => {
  const validationSchema = Yup.object({
    name: Yup.string().required("Это поле обязательное для заполнения"),
    numberPhone: Yup.string().required("Это поле обязательное для заполнения"),
  });
  const [value, setValue] = useState(0);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = (values, { resetForm }) => {
    // Отправка данных на почту
    sendEmail(values);

    // Сброс формы после отправки
    resetForm();
    setValue(0);
    handleOpen();
  };

  const styleBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  return (
    <section className="leaveFeedback">
      <h1 className="leaveFeedback__title">ОСТАВЬТЕ СВОЙ ОТЗЫВ</h1>
      <div className="leaveFeedback__wrapper">
        <div className="img">
          <img src={laptopImg} alt="laptop"></img>
        </div>
        <Formik
          initialValues={{
            rating: "0",
            massage: "",
            name: "",
            numberPhone: "",
            linkFile: "",
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form className="form">
            <Field name="rating">
              {({ field }) => (
                <Rating
                  name="rating"
                  value={value}
                  onChange={(event, newValue) => {
                    setValue(newValue);
                    field.onChange(event);
                  }}
                />
              )}
            </Field>

            <Field
              className="massage"
              type="text"
              name="massage"
              placeholder="Сообщение"
            ></Field>
            <div className="wrapperInfo">
              <div className="containerInputAndError">
                <Field
                  className="wrapperInfo__name"
                  type="text"
                  name="name"
                  placeholder="Ваше имя"
                ></Field>
                <div className="wrapperError">
                  <ErrorMessage name="name" component="p" className="error" />
                </div>
              </div>
              <div className="containerInputAndError">
                <Field
                  className="wrapperInfo__numberPhone"
                  type="text"
                  name="numberPhone"
                  placeholder="Ваш номер телефона"
                ></Field>
                <div className="wrapperError">
                  <ErrorMessage
                    name="numberPhone"
                    component="p"
                    className="error"
                  />
                </div>
              </div>
            </div>
            <div className="inputFileContainer">
              <Field
                className="inputFile"
                type="text"
                name="linkFile"
                placeholder="Ссылка на файл"
              ></Field>
              <div className="iconQuestion">
                <Tooltip
                  title="Вставьте ссылку на файл с вашей фотографией"
                  disableFocusListener
                  enterTouchDelay={50} //количество милисекунд, в течении поторых юзер должен коснуться элемента, чтобы всплыло окно
                  placement="right"
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        color: "#fff",
                        backgroundColor: "#4B67B9",
                        padding: "10px",
                        maxWidth: "100px",
                        fontFamily: "Inter",
                        fontSize: "12px",
                        fontStyle: "normal",
                        fontWeight: "400",
                      },
                    },
                    arrow: {
                      sx: {
                        color: "#4B67B9",
                      },
                    },
                  }}
                >
                  <IconButton>
                    <IconQuestion />
                  </IconButton>
                </Tooltip>
              </div>
            </div>

            <div className="wrapperButton">
              <Button className="button" variant="contained" type="submit">
                Отправить
              </Button>
            </div>
            
              <Modal open={open} onClose={handleClose}>
                <Box sx={styleBox}>
                  <ReviewsSent handleClose={handleClose} />
                </Box>
              </Modal>
            
          </Form>
        </Formik>
      </div>
    </section>
  );
};
export default LeaveFeedback;
