import masterImg from "../../../../assets/img/advantagesMasterImg.jpg";
import repairImg from "../../../../assets/img/advantagesRepairImg.jpg";
import experienceImg from "../../../../assets/img/advantagesExperienceImg.jpg";
import diagnosticsImg from "../../../../assets/img/advantagesDiagnosticsImg.jpg";

export const data = [
  {
    img: <img src={masterImg} alt="master"></img>,
    title: "Выезд мастера",
    text: "Выезжаем на дом в течение 1 часа после оформления заявки",
  },
  {
    img: <img src={repairImg} alt="repair"></img>,
    title: "Срочный ремонт",
    text: "Мы понимаем, что ноутбук может быть рабочим инструментом, поэтому предлагаем срочный ремонт тем, кто не может ждать",
  },
  {
    img: <img src={experienceImg} alt="repair"></img>,
    title: "Опыт работы",
    text: "Мы сталкивались со всем, что может вас беспокоить: от жидкостей на клавиатуре до самых старых ноутбуков",
  },
  {
    img: <img src={diagnosticsImg} alt="repair"></img>,
    title: "Диагностика",
    text: "Проводится бесплатно. Мы используем технологии и методы, которые позволяют нам за считанные минуты определить неполадки ноутбука",
  },
];
