import Avatar from "@mui/material/Avatar";
import avatar1 from "../../../../assets/img/rewiewsAvatar1.jpg";
import avatar2 from "../../../../assets/img/rewiewsAvatar2.jpg";
import avatar3 from "../../../../assets/img/rewiewsAvatar3.jpg";
import avatar4 from "../../../../assets/img/rewiewsAvatar4.jpg";
import avatar5 from "../../../../assets/img/rewiewsAvatar5.jpg";
import avatar6 from "../../../../assets/img/rewiewsAvatar6.jpg";
import avatar7 from "../../../../assets/img/rewiewsAvatar7.jpg";
import avatar8 from "../../../../assets/img/rewiewsAvatar8.jpg";
import avatar9 from "../../../../assets/img/rewiewsAvatar9.jpg";

export const dataReviewsSlider = [
  [
    {
      avatar: (
        <Avatar
          className="avatar"
          src={avatar1}
          alt="avatar"
          sx={{ width: 150, height: 150 }}
        ></Avatar>
      ),
      rating: "4.5",
      name: "Игорь Р.",
      text: "У меня старый Acer есть, в качестве печатной машинки. Держу из-за крайне удобной клавиатуры — но время его не пожалело. Думал всё уже, но нет — ребята нашли где‑то замену. Ещё поработает.",
    },
    {
      avatar: (
        <Avatar
          className="avatar"
          src={avatar2}
          alt="avatar"
          sx={{ width: 150, height: 150 }}
        ></Avatar>
      ),
      rating: "4.5",
      name: "Костя Н.",
      text: "Во время катки ноут отрубился! Жесть! Принёс сюда, они его за час осмотрели, нашли проблему с железом, поставили замену. Оч рад.",
    },
    {
      avatar: (
        <Avatar
          className="avatar"
          src={avatar3}
          alt="avatar"
          sx={{ width: 150, height: 150 }}
        ></Avatar>
      ),
      rating: "4.5",
      name: "Мария К.",
      text: "У меня старый Acer есть, в качестве печатной машинки. Держу из-за крайне удобной клавиатуры — но время его не пожалело. Думал всё уже, но нет — ребята нашли где‑то замену. Ещё поработает.",
    },
  ],
  [
    {
      avatar: (
        <Avatar
          className="avatar"
          src={avatar4}
          alt="avatar"
          sx={{ width: 150, height: 150 }}
        ></Avatar>
      ),
      rating: "4.5",
      name: "Евгений Б.",
      text: "Как клиенты, мы очень довольны работой сайта ремонта ноутбуков. Компания имеет отличную репутацию, и это не удивительно, учитывая качество их услуг. Ни разу не было проблем с ремонтом наших ноутбуков, и мы всегда получали отличное обслуживание.",
    },
    {
      avatar: (
        <Avatar
          className="avatar"
          src={avatar5}
          alt="avatar"
          sx={{ width: 150, height: 150 }}
        ></Avatar>
      ),
      rating: "4.5",
      name: "Юрий Г.",
      text: "Заказывал ремонт своего ноутбука на этом сайте и остался очень довольным. Команда была очень оперативна и профессиональна, и они действительно сделали все возможное.",
    },
    {
      avatar: (
        <Avatar
          className="avatar"
          src={avatar6}
          alt="avatar"
          sx={{ width: 150, height: 150 }}
        ></Avatar>
      ),
      rating: "4.5",
      name: "Светлана Ю.",
      text: "Мне понравилось, как быстро и профессионально был выполнен ремонт моего ноутбука. Команда сайта ремонта ноутбуков была очень отзывчивой и дала мне полную информацию о статусе моего ремонта.",
    },
  ],
  [
    {
      avatar: (
        <Avatar
          className="avatar"
          src={avatar7}
          alt="avatar"
          sx={{ width: 150, height: 150 }}
        ></Avatar>
      ),
      rating: "4.5",
      name: "Константин З.",
      text: "Я заказал услуги сайта ремонта ноутбуков, и они оправдали мои ожидания. Работа была выполнена быстро и профессионально, и я был очень доволен результатом. Я рекомендую этот сайт всем",
    },
    {
      avatar: (
        <Avatar
          className="avatar"
          src={avatar8}
          alt="avatar"
          sx={{ width: 150, height: 150 }}
        ></Avatar>
      ),
      rating: "4.5",
      name: "Олеся Н.",
      text: "Сайт ремонта ноутбуков был очень полезен для меня, когда я столкнулся с проблемой с моим ноутбуком. Они быстро и профессионально устранили проблему, и теперь мой ноутбук работает как новый.",
    },
    {
      avatar: (
        <Avatar
          className="avatar"
          src={avatar9}
          alt="avatar"
          sx={{ width: 150, height: 150 }}
        ></Avatar>
      ),
      rating: "4.5",
      name: "Марк Н.",
      text: "У меня старый Acer есть, в качестве печатной машинки. Держу из-за крайне удобной клавиатуры — но время его не пожалело. Думал всё уже, но нет — ребята нашли где‑то замену. Ещё поработает.",
    },
  ],
];
