import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import MainPage from "./components/MainPage";
import PricePage from "./components/PricePage";
import PrivacyPolicyPage from "./components/PrivacyPolicyPage";
import AdvicePage from "./components/AdvicePage";
import ReviewsPage from "./components/ReviewsPage";
import ContactsPage from "./components/ContactsPage";

function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/price" element={<PricePage />} />
          <Route path="/advice" element={<AdvicePage />} />
          <Route path="/feedback" element={<ReviewsPage />} />
          <Route path="/contacts" element={<ContactsPage />} />

          <Route path="/privacyPolicy" element={<PrivacyPolicyPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
