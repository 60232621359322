// import Avatar from "@mui/material/Avatar";
import avatar1 from "../../../../assets/img/rewiewsAvatar1.jpg";
import avatar2 from "../../../../assets/img/rewiewsAvatar2.jpg";
import avatar3 from "../../../../assets/img/rewiewsAvatar3.jpg";

export const dataReviews = [
  {
    //------------
    // avatar: (
    //   <Avatar
    //     className="avatar"
    //     src={avatar1}
    //     alt="avatar"
    //     sx={{ width: 150, height: 150 }}
    //   ></Avatar>
    // ),
    //-------------------------------
    avatar: avatar1,
    rating: "4.5",
    name: "Игорь Р.",
    text: "У меня старый Acer есть, в качестве печатной машинки. Держу из-за крайне удобной клавиатуры — но время его не пожалело. Думал всё уже, но нет — ребята нашли где‑то замену. Ещё поработает.",
  },
  {
    //-----------------
    // avatar: (
    //   <Avatar
    //     className="avatar"
    //     src={avatar2}
    //     alt="avatar"
    //     sx={{ width: 150, height: 150 }}
    //   ></Avatar>
    // ),
    //-----------------------

    avatar: avatar2,
    rating: "4.5",
    name: "Костя Н.",
    text: "Во время катки ноут отрубился! Жесть! Принёс сюда, они его за час осмотрели, нашли проблему с железом, поставили замену. Оч рад.",
  },
  {
    //---------------------------
    // avatar: (
    //   <Avatar
    //     className="avatar"
    //     src={avatar3}
    //     alt="avatar"
    //     sx={{ width: 150, height: 150 }}
    //   ></Avatar>
    // ),
    //------------------

    avatar: avatar3,
    rating: "4.5",
    name: "Мария К.",
    text: "Обратилась с поломкой двух ноутбуков. Сразу же договорились о времени встречи и цене. Работы выполнена была в срок и на высшем уровне, также сделана очистка и обслуживание всех систем ноутбуков. Очень квалифицированный и порядочный специалист.",
  },
];
