export const PhoneIcon = () => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4121 15.125C11.5665 15.1248 12.5988 14.7377 13.3975 13.939L14.5638 12.7728C15.1454 12.1909 15.1454 11.2439 14.5637 10.6624L12.7916 8.89025C12.4884 8.58689 12.0698 8.42948 11.6409 8.45599C11.2129 8.48346 10.8171 8.69391 10.5551 9.0332L9.40567 10.5216C8.56965 10.0727 7.78924 9.48863 7.08287 8.7821C6.38012 8.07935 5.79805 7.30327 5.35029 6.4728L6.84246 5.32056C7.18175 5.05864 7.39222 4.66301 7.41966 4.23518C7.44712 3.80722 7.28893 3.38771 6.98556 3.08434L5.21361 1.31223C4.93176 1.03022 4.55698 0.875 4.1582 0.875C3.75958 0.875 3.38482 1.03021 3.1031 1.31223L2.17545 2.23957C2.07972 2.32086 2.00015 2.39368 1.92623 2.46744C-0.234014 4.62737 0.616144 8.4946 3.99307 11.8714C6.12158 14 8.44454 15.1248 10.4119 15.1248L10.4121 15.125ZM2.63726 3.17916C2.70224 3.11434 2.77066 3.05361 2.84034 2.99507C2.85117 2.98581 2.86168 2.97624 2.87188 2.96604L3.81445 2.02364C3.90626 1.93167 4.02835 1.88114 4.15814 1.88114C4.28808 1.88114 4.41018 1.93167 4.50214 2.02364L6.27409 3.79575C6.37437 3.89619 6.42475 4.02942 6.41565 4.17082C6.40654 4.31222 6.33969 4.43777 6.22748 4.52456L4.53427 5.832C4.26811 6.03758 4.18446 6.40358 4.33528 6.70268C4.84487 7.71318 5.52986 8.65213 6.37122 9.49349C7.21632 10.3387 8.16045 11.0263 9.17758 11.5368C9.47607 11.6865 9.84156 11.6029 10.0467 11.3373L11.351 9.64821C11.4376 9.536 11.5633 9.46915 11.7047 9.4602C11.8466 9.44985 11.9794 9.50148 12.0797 9.6016L13.8518 11.3737C14.0413 11.5633 14.0412 11.872 13.8518 12.0616L12.6856 13.2277C10.944 14.9688 7.66202 14.119 4.70388 11.1607C1.74627 8.20232 0.896308 4.92025 2.63732 3.17903L2.63726 3.17916Z"
        fill="#4B67B9"
      />
    </svg>
  );
};
