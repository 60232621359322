export const dataAccordionBeforeTheRepair = [
  {
    title: "Ноутбук залит жидкостью",
    text: "Перевернулся бокал, мимо пробегал ребёнок, внезапно пошёл дождь, внезапно упал в воду — и, конечно, всё это случилось именно с Вашим ноутбуком.Отключите ноутбук и выньте аккумулятор,отключите все внешние накопители: флэшки, жёсткие,переверните ноут, дайте жидкости стечь, протрите остатки жидкости салфеткой, оставьте устройство сушиться. Безусловно, вода попала в ноутбук или кетчуп — важно, результат и длительность ремонта может варьироваться. Мы приступим к устранению проблемы сразу же.",
  },
  {
    title: "Перегрев",
    text: "Ноутбук греется как хорошая батарея зимой. Проблема системы охлаждения, одна из самых часто встречаемых в нашей практике. На состояние системы охлаждения может повлиять ряд факторов, хотя наиболее типичный — пыль и старая термопаста. Но отключения, перепады напряжения и даже заводской брак — всё что угодно может являться причиной. Мы точно установим причину проблемы и устраним её быстро и эффективно.",
  },
  {
    title: "Ноутбук не включается",
    text: "Нажатие кнопки «вкл» ничего не изменило, ноутбук не реагирует. Для начала попытайтесь подключить его к сети (очевидно, но были случаи). Если это ничего не меняет, то есть вероятность неисправности кабеля. Также посмотрите не горят ли индикаторы — возможно, дело в дисплее. Но даже если ноутбук начал подавать неидеальные признаки жизни проблема уже есть. Какая именно — выясним и сообщим вам, а потом устраним полностью.",
  },
  {
    title: "Ноутбук не заряжается",
    text: "Автономная работа ноутбука — одна из самых приятных возможностей и когда она внезапно исчезает дискомфорт очевиден. Для начала нужно попробовать зарядку через другой кабель — при наличии оного. Если замена кабеля не помогла, то причина может быть серьёзней и требовать ремонта. В тех случаях, когда дело в аккумуляторе или разъёме гнёзда зарядки мы придём на помощь — заменим неисправный компонент и устраним неполадку.",
  },
  {
    title: "Пропал звук",
    text: "Если такое случилось с Вашим ноутбуком сначала проверьте наушники — возможно, они неисправны. Затем микшер в системе (иконка динамика, обычно в правом нижнем углу) – звук может быть отключён программно. Так же звук может быть отключён в БИОСе или пропасть после переустановки системы. Если самолечение не помогло — мы придём на помощь и вернём Вашему ноутбуку звук.",
  },
  {
    title: "Проблемы с клавиатурой",
    text: "Если у вас перестала работать клавиатура — помните: у ноутбуков существует комбинация клавиш, блокирующая её. Помимо очевидной кнопки с замочком и стандартной комбинации клавиш Fn + NumLock для каждого ноутбука она своя — информацию для конкретной модели можно найти в сети. Если клавиатура по‑прежнему вас игнорирует — мы исправим ситуацию. Если же у вас стали западать клавиши, некоторые из них перестали реагировать на нажатие или в целом состояние клавиатуры вас не устраивает — пусть даже просто внешним видом — мы можем всё привести в порядок.",
  },
  {
    title: "Не работает USB",
    text: "Если у вас перестал работать один USB порт — это повод задуматься. Но если все порты перестали подавать признаки жизни — скорее всего дело в серьёзной проблеме. За USB порты в ноутбуке отвечает компонент, называемый «южный мост» (он также отвечает за клавиатуру, сенсорную панель, динамики и микрофон) - он соединяет сами порты с остальными компонентами ноутбука. Чтобы выяснить нужна ли его замена или причина куда проще (отслоение, перегрев) мы должны осмотреть устройство и провести диагностику.",
  },
];
