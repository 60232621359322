import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { dataPrice } from "./constants/dataPrice";
import PriceTableMobile from "./priceTableMobile";
import OrderService from "../../ModalWindows/OrderService";

const Price = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const styleBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  return (
    <section className="price">
      <h1 className="price__title">ПРАЙС</h1>
      <PriceTableMobile />
      <div className="price__table">
        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="head" align="left">
                  Наименование
                </TableCell>
                <TableCell className="head" align="left">
                  Время
                </TableCell>
                <TableCell className="head" align="center">
                  Гарантии
                </TableCell>
                <TableCell className="head" align="right">
                  Стоимость
                </TableCell>
                <TableCell className="head" align="left"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataPrice.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell
                      className="itemTable"
                      align="left"
                      sx={{ maxWidth: 233 }}
                    >
                      {item.name}
                    </TableCell>
                    <TableCell className="itemTable time" align="left">
                      {item.time}
                    </TableCell>
                    <TableCell className="itemTable" align="center">
                      {item.guarantees}
                    </TableCell>
                    <TableCell className="itemTable" align="right">
                      {item.price}
                    </TableCell>
                    <TableCell
                      className="itemTable"
                      align="right"
                      padding="normal"
                      // sx={{padding: "0"}}
                    >
                      <Button
                        className="orderButton"
                        variant="contained"
                        onClick={handleOpen}
                      >
                        Заказать
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <Modal open={open} onClose={handleClose}>
        <Box sx={styleBox}>
          <OrderService handleClose={handleClose} />
        </Box>
      </Modal>
    </section>
  );
};
export default Price;
