export const Sber = () => {
  return (
    <svg
      width="128"
      height="20"
      viewBox="0 0 128 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.6468 3.88591C19.1155 4.50009 19.5124 5.16734 19.8292 5.87428L10.8407 12.5894L7.08419 10.2029V7.33178L10.8407 9.71829L18.6468 3.88591Z"
        fill="white"
      />
      <path
        d="M3.25037 9.99988C3.25037 9.87105 3.25347 9.74307 3.25967 9.61582L0.983102 9.50277C0.975353 9.66768 0.970706 9.83417 0.970706 10.0022C0.969737 11.3151 1.22455 12.6153 1.72054 13.8281C2.21652 15.041 2.9439 16.1426 3.86097 17.0699L5.4758 15.4341C4.76987 14.7216 4.20986 13.8747 3.82794 12.9421C3.44603 12.0095 3.24975 11.0096 3.25037 9.99988Z"
        fill="white"
      />
      <path
        d="M10.8387 2.30959C10.9657 2.30959 11.092 2.31431 11.2175 2.32059L11.3314 0.0126002C11.1682 0.00474723 11.0039 0.000821219 10.8387 0.000821219C9.54255 -0.000780597 8.25899 0.25709 7.06155 0.759625C5.86411 1.26216 4.77642 1.99947 3.86092 2.9292L5.47575 4.56576C6.17885 3.8501 7.01461 3.28235 7.93498 2.89515C8.85534 2.50796 9.8421 2.30896 10.8387 2.30959Z"
        fill="white"
      />
      <path
        d="M10.8387 17.6903C10.7116 17.6903 10.5853 17.6903 10.459 17.6801L10.3451 19.9873C10.5088 19.9957 10.6734 19.9999 10.8387 19.9999C12.1342 20.0013 13.4172 19.7432 14.614 19.2405C15.8108 18.7379 16.8977 18.0005 17.8125 17.0707L16.2008 15.435C15.4976 16.1503 14.6619 16.7177 13.7417 17.1047C12.8215 17.4918 11.835 17.6908 10.8387 17.6903Z"
        fill="white"
      />
      <path
        d="M15.116 3.65165L17.0346 2.21848C15.281 0.779391 13.0921 -0.00399476 10.8356 1.53192e-05V2.30957C12.3636 2.30765 13.8561 2.77563 15.116 3.65165Z"
        fill="white"
      />
      <path
        d="M20.706 10.0001C20.7072 9.39924 20.6554 8.79947 20.551 8.20801L18.4271 9.7943C18.4271 9.86266 18.4271 9.93096 18.4271 10.0001C18.4277 11.0746 18.2054 12.1372 17.7748 13.1191C17.3442 14.101 16.7147 14.9802 15.9274 15.6997L17.4593 17.4157C18.4829 16.4791 19.3009 15.335 19.8602 14.0575C20.4196 12.78 20.7077 11.3977 20.706 10.0001Z"
        fill="white"
      />
      <path
        d="M10.8387 17.6903C9.77834 17.6907 8.72987 17.4655 7.76089 17.0291C6.79197 16.5927 5.92426 15.9549 5.21398 15.1571L3.52167 16.7088C4.44567 17.7464 5.57453 18.5755 6.83507 19.1425C8.0956 19.7095 9.45952 20.0016 10.8387 19.9999V17.6903Z"
        fill="white"
      />
      <path
        d="M5.75011 4.30011L4.21896 2.58423C3.19503 3.5206 2.37673 4.66463 1.81714 5.94213C1.25756 7.21962 0.969178 8.60206 0.970709 9.99978L3.25037 9.99988C3.2499 8.92543 3.47219 7.86268 3.90281 6.88081C4.33343 5.89897 4.96279 5.01973 5.75011 4.30011Z"
        fill="white"
      />
      <path
        d="M34.5909 13.3673C33.8945 13.7452 33.1142 13.9408 32.3223 13.9362C29.9521 13.9362 28.2326 12.2407 28.2326 9.90598C28.2326 7.5712 29.9521 5.87202 32.3223 5.87202C33.177 5.85748 34.0141 6.11718 34.7111 6.61323L36.5969 5.22162L36.4694 5.13083C35.3693 4.16172 33.8994 3.64845 32.2169 3.64845C30.3921 3.64845 28.7373 4.26363 27.5559 5.38283C26.9585 5.95909 26.4861 6.65255 26.1679 7.41992C25.8499 8.1873 25.6929 9.01209 25.707 9.84294C25.6944 10.6822 25.8506 11.5154 26.1664 12.2929C26.4821 13.0704 26.9509 13.7761 27.5448 14.3679C28.7318 15.5316 30.3828 16.1727 32.1947 16.1727C34.088 16.1727 35.7428 15.5112 36.8632 14.3068L35.1752 13.0468L34.5909 13.3673Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M44.6119 8.67945H41.3313V5.8838H46.567L49.3887 3.79325H38.6366V16.3176H44.2649C47.4204 16.3176 49.2313 14.8846 49.2313 12.3854C49.2313 9.99456 47.591 8.67945 44.6119 8.67945ZM44.1435 14.2327H41.3313V10.7624H44.1435C45.8502 10.7624 46.6504 11.3439 46.6504 12.4976C46.6504 13.6511 45.799 14.2327 44.1435 14.2327Z"
        fill="white"
      />
      <path
        d="M60.4193 3.80042L57.5959 5.88463H53.6663V8.94819H59.4269V11.0324H53.6663V14.2405H60.4193V16.3247H50.97V3.80042H60.4193Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M62.2217 3.79862H67.3652C70.6469 3.79862 72.6769 5.51556 72.6769 8.28011C72.6769 11.0447 70.6469 12.775 67.3652 12.775H64.9176V16.3229H62.2217V3.79862ZM64.9176 10.6892H67.3084C69.0345 10.6892 69.9872 9.83736 69.9872 8.28775C69.9872 6.73814 69.0345 5.88443 67.3084 5.88443H64.9176V10.6892Z"
        fill="white"
      />
      <path
        d="M118.63 16.2996H115.896V3.77534H118.63V9.03562H120.117L124.135 3.77534H127.232L122.467 9.73329L128 16.2996H124.374L120.307 11.3739H118.63V16.2996Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M83.367 8.67945H80.0878V5.8838H87.1348V3.79325H77.3912V16.3176H83.0123C86.1675 16.3176 87.9786 14.8846 87.9786 12.3854C87.9859 9.99456 86.346 8.67945 83.367 8.67945ZM82.8986 14.2327H80.0878V10.7624H82.8986C84.6052 10.7624 85.405 11.3439 85.405 12.4976C85.405 13.6511 84.5612 14.2327 82.8986 14.2327Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M97.9086 13.9195H92.1267L91.1465 16.3516H88.2378L93.6608 3.82729H96.5689L101.904 16.3516H98.8888L97.9086 13.9195ZM97.003 11.6502L95.0348 6.78602L93.0555 11.6502H97.003Z"
        fill="white"
      />
      <path
        d="M105.584 3.82729V9.05721H111.24V3.82729H114.058V16.3516H111.24V11.4166H105.584V16.3516H102.765V3.82729H105.584Z"
        fill="white"
      />
    </svg>
  );
};

export const Money = () => {
  return (
    <svg
      width="95"
      height="20"
      viewBox="0 0 95 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.0465 0C13.34 0 8.81919 4.49274 8.81919 10C8.81919 15.5072 13.414 20 19.0465 20C24.6789 20 29.2738 15.5072 29.2738 10C29.3479 4.49274 24.6789 0 19.0465 0ZM19.0465 13.6956C16.9714 13.6956 15.1927 12.029 15.1927 10C15.1927 7.97099 16.8972 6.30433 19.0465 6.30433C21.1957 6.30433 22.9003 7.97099 22.9003 10C22.8261 12.029 21.1957 13.6956 19.0465 13.6956Z"
        fill="white"
      />
      <path
        d="M8.81899 2.97095V17.5361H5.18752L0.518555 2.97095H8.81899Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M55.2471 7.89877C56.5071 7.31902 57.8412 7.24658 59.1007 7.68136C61.0277 8.40599 62.2135 10.0727 62.2877 12.2466C62.2877 13.1886 62.0652 13.9857 61.6206 14.7104C61.1761 15.3625 60.5832 15.8698 59.8419 16.3045C59.4716 16.522 59.1007 16.6669 58.7303 16.7393C58.2858 16.8118 57.8412 16.8842 57.3961 16.8842C56.5812 16.8842 55.6916 16.6669 54.9509 16.3045C54.2096 15.8698 53.6167 15.3625 53.1722 14.6379C52.7271 13.9133 52.5051 13.1161 52.5051 12.1741C52.5051 11.2321 52.7271 10.435 53.1722 9.71037C53.6909 8.91325 54.358 8.26106 55.2471 7.89877ZM58.9528 14.0582C59.4716 13.6234 59.7677 12.9712 59.7677 12.1741C59.8419 11.3045 59.4716 10.5074 58.6561 10.0002C58.2858 9.71037 57.8412 9.56544 57.2483 9.56544C56.6554 9.56544 56.2104 9.71037 55.84 10.0002C55.1729 10.5074 54.8767 11.3045 54.8767 12.1741C54.9509 12.9712 55.1729 13.6234 55.6916 14.0582C56.1362 14.348 56.6554 14.5654 57.322 14.5654C58.0632 14.5654 58.5819 14.4205 58.9528 14.0582Z"
        fill="white"
      />
      <path
        d="M71.4774 8.11623C70.9586 7.75389 70.3657 7.53653 69.6245 7.46404C68.3645 7.31911 67.1787 7.75389 66.4374 8.62349V7.60896H63.918V16.5945H66.4374V11.8843C66.4374 10.9482 66.6025 10.6571 66.7431 10.4091C66.7657 10.3693 66.7878 10.3303 66.8083 10.2902C67.1045 9.78289 67.6974 9.49304 68.3645 9.56548C69.0316 9.56548 69.6987 9.85538 69.9949 10.3626C70.2174 10.7249 70.2174 11.1597 70.2174 11.4496V16.5945H72.7373V11.1597C72.7373 9.71046 72.2923 8.76842 71.4774 8.11623Z"
        fill="white"
      />
      <path
        d="M90.0793 12.8263L92.2283 7.60893H94.9703L89.7826 20.0002H86.9664L88.5226 16.5944L84.8173 7.60893H87.6335L89.7826 12.8263C89.7826 12.8625 89.8011 12.8988 89.8196 12.935C89.8382 12.9713 89.8567 13.0075 89.8567 13.0437L89.9309 13.1886L90.0051 13.0437C90.0051 13.0075 90.0236 12.9713 90.0422 12.935C90.0607 12.8988 90.0793 12.8625 90.0793 12.8263Z"
        fill="white"
      />
      <path
        d="M49.6146 8.11582C49.17 7.75348 48.503 7.53612 47.7619 7.46363H47.3172C46.2796 7.46363 45.4644 7.89841 44.7233 8.76801L44.5751 8.91294H44.2786C44.0564 8.62315 43.8339 8.33315 43.5376 8.18826C43.0928 7.75348 42.5 7.53612 41.7589 7.46363C40.5731 7.31871 39.6097 7.68105 38.7945 8.5506V7.60856H36.2747V16.5941H38.7945V11.8114C38.7945 10.8606 38.9247 10.61 39.0631 10.3436L39.0909 10.2898C39.3873 9.78249 39.9802 9.49264 40.5731 9.49264C41.166 9.49264 41.7589 9.78249 42.0553 10.2898C42.2777 10.652 42.2777 11.0868 42.2777 11.5216V16.5216H44.8715V11.739C44.8715 10.8695 45.0197 10.5071 45.168 10.2173C45.4644 9.71 46.0573 9.49264 46.6502 9.49264C47.2431 9.49264 47.7619 9.85493 48.0583 10.2898C48.2065 10.5796 48.2806 10.9419 48.2806 11.2317V16.5941H50.8004V11.1593C50.8004 10.652 50.7263 10.2173 50.6522 9.78249C50.504 9.05786 50.1334 8.47816 49.6146 8.11582Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M82.2974 13.8409C81.927 14.2032 81.0374 14.8554 79.8516 14.9278C78.8883 14.9278 78.0729 14.7105 77.5542 14.1307L77.5525 14.1286C77.3311 13.8394 77.1095 13.5501 77.0355 13.1887H84.0761V13.1162C84.1503 12.754 84.1503 12.3916 84.1503 12.1018C84.1503 11.8119 84.0761 11.3771 83.9277 10.7974C83.6315 9.78291 83.0386 8.98584 82.2232 8.33365C81.3341 7.68146 80.4445 7.4641 80.2225 7.4641C78.3696 7.17425 76.2942 7.97131 75.2568 9.63798C74.5155 10.7974 74.5155 11.9568 74.5897 12.3191C74.5897 12.754 74.6639 14.1307 75.7755 15.2902C76.9259 16.5473 78.5706 16.717 79.2992 16.7921C79.3686 16.7993 79.4297 16.8056 79.4812 16.8119C82.0012 16.8843 83.7057 15.3626 84.0761 15.0003L82.2974 13.8409ZM77.9251 10.0728C78.2954 9.7829 78.74 9.63798 79.3329 9.63798C79.9258 9.63798 80.3703 9.7829 80.7412 10.0728C81.1116 10.3626 81.4083 10.7249 81.5561 11.2322H77.0355C77.258 10.7249 77.5542 10.3626 77.9251 10.0728Z"
        fill="white"
      />
    </svg>
  );
};

export const World = () => {
  return (
    <svg
      width="67"
      height="18"
      viewBox="0 0 67 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.72593 0.00179541C6.69221 0.00236926 6.66097 0.00290098 6.63245 0.00307161L0.530273 0.00312842V18H6.42694V7.38627H6.82235L10.1094 18H14.5459L17.833 7.39413H18.2284V18H24.1251V0.00312842L18.3397 0.00312121C18.2948 0.00312121 18.2459 0.00244996 18.1935 0.00173004C17.3695 -0.00958113 15.6703 -0.032907 14.9653 2.34646C14.5271 3.82526 13.7221 6.58899 12.5502 10.6376H12.1052C11.0124 6.7974 10.2161 4.03367 9.71618 2.34646C9.00995 -0.0370767 7.38915 -0.009492 6.72593 0.00179541Z"
        fill="white"
      />
      <path
        d="M32.5926 10.6169V0.00312842H26.5728V18H32.279C32.279 18 34.0117 17.8428 34.7878 16.1139L38.7079 7.38627H39.1783V18H45.1981V0.00312842H39.4919C39.4919 0.00312842 37.8376 0.152447 37.0615 1.88926L33.063 10.6169H32.5926Z"
        fill="white"
      />
      <path
        d="M59.6091 12.639C62.5286 12.639 65.1656 10.9349 66.0053 8.55536H47.5716V18H53.2961V12.639H59.6091Z"
        fill="white"
      />
      <path
        d="M47.2715 0.00354004H59.8852C64.7386 0.00354004 67.0846 4.11021 66.4031 7.44026H54.5941C51.0222 7.44026 47.5717 4.01025 47.2715 0.00354004Z"
        fill="white"
      />
    </svg>
  );
};
