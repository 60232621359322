export const dataPrice = [
  {
    name: "Диагностика при согласии на ремонт или не сможем отремонтировать",
    time: "от 3 часов",
    guarantees: "—",
    price: "0 руб.",
  },
  {
    name: "Диагностика при отказе от ремонта",
    time: "от 3 часов",
    guarantees: "—",
    price: "400 руб. ",
  },
  {
    name: "Срочная диагностика + ремонт",
    time: "30-60 мин.",
    guarantees: "—",
    price: "1400 руб.",
  },
  {
    name: "Чистка от пыли и замена термопасты Arctic MX-4",
    time: "30-60 мин.",
    guarantees: "—",
    price: "1900 руб.",
  },
  {
    name: "Замена матрицы (экрана)",
    time: "30-60 мин.",
    guarantees: "3 мес.",
    price: "от 1900 руб.",
  },
  {
    name: "Замена жесткого диска HDD, SSD",
    time: "30 мин.",
    guarantees: "3 мес.",
    price: "1400 руб.",
  },
  {
    name: "Замена клавиатуры",
    time: "1 час",
    guarantees: "3 мес.",
    price: "от 1900 руб.",
  },
  {
    name: "Замена разъема зарядки",
    time: "1 час",
    guarantees: "3 мес.",
    price: "2400 руб.",
  },
  {
    name: "Замена USB разъема",
    time: "1-2 часа",
    guarantees: "3 мес.",
    price: "2400 руб.",
  },
  {
    name: "Замена тачпада",
    time: "1 час",
    guarantees: "3 мес.",
    price: "2400 руб.",
  },
  {
    name: "Замена оперативной памяти",
    time: "30 мин.",
    guarantees: "3 мес.",
    price: "1400 руб.",
  },
  {
    name: "Замена аккумулятора",
    time: "30 мин.",
    guarantees: "3 мес.",
    price: "1400 руб.",
  },
  {
    name: "Замена кулера",
    time: "1 час",
    guarantees: "3 мес.",
    price: "2400 руб.",
  },
  {
    name: "Замена шлейфа матрицы",
    time: "1 час",
    guarantees: "3 мес.",
    price: "2400 руб.",
  },
  {
    name: "Ремонт материнской платы",
    time: "2-3 часа",
    guarantees: "3 мес.",
    price: "от 2400 руб.",
  },
  {
    name: "Замена видеокарты",
    time: "2-3 часа",
    guarantees: "3 мес.",
    price: "от 3400 руб.",
  },
  {
    name: "Замена процессора",
    time: "2-3 часа",
    guarantees: "3 мес.",
    price: "от 3400 руб.",
  },
  {
    name: "Замена северного моста",
    time: "2-3 часа",
    guarantees: "3 мес.",
    price: "от 3400 руб.",
  },
  {
    name: "Замена южного моста",
    time: "2-3 часа",
    guarantees: "3 мес.",
    price: "от 3400 руб.",
  },
  {
    name: "Замена модуля WI-FI",
    time: "1-2 часа",
    guarantees: "3 мес.",
    price: "2400 руб.",
  },
  {
    name: "Замена мультиконтроллера",
    time: "1-2 часа",
    guarantees: "3 мес.",
    price: "2400 руб.",
  },
  {
    name: "Замена материнской платы",
    time: "1 час",
    guarantees: "3 мес.",
    price: "2400 руб.",
  },
  {
    name: "Замена петель крепления матрицы",
    time: "1-2 часа",
    guarantees: "3 мес.",
    price: "2400 руб.",
  },
  {
    name: "Корпусной ремонт",
    time: "1-2 часа",
    guarantees: "3 мес.",
    price: "от 3400 руб.",
  },
  {
    name: "Восстановление после воды",
    time: "2-3 часа",
    guarantees: "—",
    price: "2400 руб.",
  },
  {
    name: "Прошивка BIOS материнской платы",
    time: "2-3 часа",
    guarantees: "—",
    price: "2400 руб.",
  },
  {
    name: "Установка ОС Windows",
    time: "1 час",
    guarantees: "—",
    price: "1400 руб.",
  },
  {
    name: "Установка Office",
    time: "1 час",
    guarantees: "—",
    price: "1400 руб.",
  },
  {
    name: "Установка Windows, Office, антивируса",
    time: "1-2 часа",
    guarantees: "—",
    price: "2400 руб.",
  },
  {
    name: "Сохранение данных",
    time: "от 1 часа",
    guarantees: "—",
    price: "900 руб.",
  },
  {
    name: "Ремонт блока питания",
    time: "1 час",
    guarantees: "3 мес.",
    price: "1400 руб.",
  },
  {
    name: "Блок питания для ноутбука",
    time: "1 день",
    guarantees: "3 мес.",
    price: "от 2000 руб.",
  },
  {
    name: "Аккумулятор для ноутбука",
    time: "1 день",
    guarantees: "3 мес.",
    price: "от 2900 руб.",
  },
  {
    name: "Установка запчасти клиента",
    time: "1-2 часа",
    guarantees: "—",
    price: "от 1400 руб.",
  },
];
