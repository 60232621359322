import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import { dataAccordion } from "./constants/dataAccordion";
// import handWithLaptop from "../../../assets/img/handWithLaptop.jpg";

const Services = () => {
  return (
    <section className="services">
      {/* <img
        className="services__img"
        src={handWithLaptop}
        alt="hand with laptop"
      ></img> */}
      <div className="services__container">
        <h1 className="services__title">НАШИ УСЛУГИ</h1>
        <div className="services__wrapperAccordion">
          {dataAccordion.map((item, index) => {
            return (
              <Accordion
                className="accordion"
                key={index}
                variant="outlined"
                sx={{
                  border: "none",
                  "& .MuiAccordionDetails-root": {
                    backgroundColor: "#F4F7FF",
                  },
                  "&.MuiAccordion-root:first-of-type": {
                    borderRadius: "10px",
                  },
                  "&.MuiAccordion-root:last-of-type": {
                    borderRadius: "10px",
                  },
                  "&:before": {
                    display: "none",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <AddIcon sx={{ color: "#4B67B9", maxWidth: "none" }} />
                  }
                  variant="outlined"
                  sx={{
                    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                      transform: "rotate(45deg)",
                    },
                    borderRadius: "10px",
                    border: "none",
                  }}
                >
                  <Typography className="title">{item.title} </Typography>
                </AccordionSummary>
                <AccordionDetails variant="outlined">
                  <Typography className="text">{item.text}</Typography>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
    </section>
  );
};
export default Services;
