import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import { dataAccordionBeforeTheRepair } from "./constants/dataAccordionBeforeTheRepair";

const BeforeTheRepair = () => {
  return (
    <section className="beforeTheRepair">
      <h1 className="beforeTheRepair__title desktop">
        ТИПИЧНЫЕ СЛУЧАИ, ЧТО ДЕЛАТЬ ДО РЕМОНТА
      </h1>
      <h1 className="beforeTheRepair__title mobile">ЧТО ДЕЛАТЬ ДО РЕМОНТА</h1>
      <div className="beforeTheRepair__wrapperAccordion">
        {dataAccordionBeforeTheRepair.map((item, index) => {
          return (
            <Accordion
              className="accordion"
              key={index}
              variant="outlined"
              sx={{
                border: "none",
                "& .MuiAccordionDetails-root": {
                  backgroundColor: "#F4F7FF",
                },
                "&.MuiAccordion-root:first-of-type": {
                  borderRadius: "10px",
                },
                "&.MuiAccordion-root:last-of-type": {
                  borderRadius: "10px",
                },
                "&:before": {
                  display: "none",
                },
              }}
            >
              <AccordionSummary
                expandIcon={
                  <AddIcon sx={{ color: "#4B67B9", maxWidth: "none" }} />
                }
                variant="outlined"
                sx={{
                  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                    transform: "rotate(45deg)",
                  },
                  borderRadius: "10px",
                  border: "none",
                }}
              >
                <Typography className="title">{item.title} </Typography>
              </AccordionSummary>
              <AccordionDetails variant="outlined">
                <Typography className="text">{item.text}</Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </section>
  );
};
export default BeforeTheRepair;
