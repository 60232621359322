import Footer from "../Footer";
import Header from "../Header";
import Questions from "../MainPage/Questions";
import Price from "./Price";
import bacgroundImg from "../../assets/img/backgroundPrice.jpg";

const PricePage = () => {
  return (
    <>
      <Header isMainPage={false} />
      <div className="wrapperPricePage">
        <img
          className="wrapperPricePage__background"
          src={bacgroundImg}
          alt="laptop"
        ></img>
        <Price />
        <Questions />
      </div>
      <Footer />
    </>
  );
};

export default PricePage;
