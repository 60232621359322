import { Link } from "react-router-dom";
import { Sber, Money, World } from "../../assets/svg/footerIcons";
import { Logo } from "../../assets/svg/logo";
import { PhoneIcon } from "../../assets/svg/phoneIcon";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { dataFooter } from "./constants/dataFooter";
import Success from "../ModalWindows/Success";
import sendEmail from "../ModalWindows/sendEmail ";

const Footer = () => {
  const validationSchema = Yup.object({
    numberPhone: Yup.string().required("Это поле обязательное для заполнения"),
  });
  const [valueNumber, setValueNumber] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onSubmit = (values, { resetForm }) => {
    // Отправка данных на почту
    sendEmail(values);
    setValueNumber(values.numberPhone);

    // Сброс формы после отправки
    resetForm();
    handleOpen();
  };

  const styleBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__links">
          <Link to="/">
            <Logo />
          </Link>

          <ul className="list">
            {dataFooter.map((item, index) => {
              return (
                <Link key={index} className="item" to={item.link}>
                  {item.name}
                </Link>
              );
            })}
          </ul>
          <div className="wrapperNumber">
            <div className="icon">
              <PhoneIcon />
            </div>
            <p className="number">8 (916) 791-28-44</p>
          </div>
          <div className="iconsPartnersMobile">
            <World />
            <Money />
            <Sber />
          </div>
          <Link className="privacyPolicyFooter" to="/privacyPolicy">
            Политика конфиденциальности
          </Link>
          <p className="textLawMobile">2023© Все права защищены</p>
        </div>
        <div className="footer__submit">
          <div className="background"></div>
          <h4 className="title">Нужен ремонт? Оставь заявку!</h4>
          <Formik
            initialValues={{ numberPhone: "" }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form className="form">
              <div className="wrapperNumberAndButton">
                <Field
                  className="numberPhone"
                  type="text"
                  name="numberPhone"
                  placeholder="Ваш телефон"
                ></Field>
                <div className="wrapperErrorMobile">
                  <ErrorMessage
                    name="numberPhone"
                    component="p"
                    className="error"
                  />
                </div>
                <Button className="button" variant="contained" type="submit">
                  Оставить заявку
                </Button>
              </div>

              <div className="wrapperError">
                <ErrorMessage
                  name="numberPhone"
                  component="p"
                  className="error"
                />
              </div>
            </Form>
          </Formik>

          <Modal open={open} onClose={handleClose}>
            <Box sx={styleBox}>
              <Success handleClose={handleClose} numberPhone={valueNumber} />
            </Box>
          </Modal>
          <div className="iconsPartners desktop">
            <World />
            <Money />
            <Sber />
          </div>
          <p className="text desktop">2023© Все права защищены</p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
