import {
  EmailIcon,
  MapIcon,
  PhoneIcon,
  TimeIcon,
} from "../../../../assets/svg/contactsIcons";

export const dataContacts = [
  {
    icon: <PhoneIcon />,
    text: "8 (916) 791-28-44",
  },
  {
    icon: <MapIcon />,
    text: "г. Москва, ул. Братиславская, 14 (250м от станции метро Братиславская)",
  },
  {
    icon: <TimeIcon />,
    text: "Ежедневно с 9:00 до 22:00",
  },
  {
    icon: <EmailIcon />,
    text: "mail@remonout.ru ",
  },
];
