import { Button } from "@mui/material";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import OrderService from "../../ModalWindows/OrderService";

const Discount = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const styleBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  return (
    <section className="discount">
      {/* <div className="wave"></div> */}
      <div className="discount__wrapperText">
        <p className="text">Oформи заказ прямо сейчас и получи </p>
        <h1 className="title">СКИДКУ 15%</h1>
        <Button className="button" variant="contained" onClick={handleOpen}>
          Заказать ремонт
        </Button>
        <Modal open={open} onClose={handleClose}>
          <Box sx={styleBox}>
            <OrderService handleClose={handleClose} />
          </Box>
        </Modal>
      </div>
      <Button className="buttonMobile" variant="contained" onClick={handleOpen}>
        Заказать ремонт
      </Button>
    </section>
  );
};
export default Discount;
