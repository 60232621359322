export const dataFooter = [
  {
    name: "Главная",
    link: "/",
  },
  {
    name: "Прайс",
    link: "/price",
  },
  {
    name: "Советы",
    link: "/advice",
  },
  {
    name: "Отзывы",
    link: "/feedback",
  },
  {
    name: "Контакты",
    link: "/contacts",
  },
];
