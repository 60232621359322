export const NextBtn = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="60" height="60" rx="30" fill="#4B67B9" />
      <path
        d="M40.9758 30.6633L32.5383 39.1008C32.3624 39.2767 32.1238 39.3756 31.875 39.3756C31.6263 39.3756 31.3877 39.2767 31.2118 39.1008C31.0358 38.9249 30.937 38.6863 30.937 38.4375C30.937 38.1888 31.0358 37.9502 31.2118 37.7742L38.0497 30.9375H19.6875C19.4389 30.9375 19.2004 30.8388 19.0246 30.6629C18.8488 30.4871 18.75 30.2487 18.75 30C18.75 29.7514 18.8488 29.5129 19.0246 29.3371C19.2004 29.1613 19.4389 29.0625 19.6875 29.0625H38.0497L31.2118 22.2258C31.0358 22.0499 30.937 21.8113 30.937 21.5625C30.937 21.3138 31.0358 21.0752 31.2118 20.8993C31.3877 20.7233 31.6263 20.6245 31.875 20.6245C32.1238 20.6245 32.3624 20.7233 32.5383 20.8993L40.9758 29.3368C41.063 29.4238 41.1322 29.5272 41.1793 29.641C41.2265 29.7548 41.2508 29.8768 41.2508 30C41.2508 30.1232 41.2265 30.2452 41.1793 30.359C41.1322 30.4728 41.063 30.5762 40.9758 30.6633Z"
        fill="#F4F7FF"
      />
    </svg>
  );
};

export const PrevBtn = () => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* <g opacity="0.5"> */}
      <rect width="60" height="60" rx="30" fill="#4B67B9" />
      <path
        d="M19.0251 30.6633L27.4627 39.1008C27.6386 39.2767 27.8772 39.3756 28.1259 39.3756C28.3747 39.3756 28.6133 39.2767 28.7892 39.1008C28.9651 38.9249 29.064 38.6863 29.064 38.4375C29.064 38.1888 28.9651 37.9502 28.7892 37.7743L21.9513 30.9375H40.3135C40.5621 30.9375 40.8006 30.8388 40.9764 30.6629C41.1522 30.4871 41.251 30.2487 41.251 30C41.251 29.7514 41.1522 29.5129 40.9764 29.3371C40.8006 29.1613 40.5621 29.0625 40.3135 29.0625H21.9513L28.7892 22.2258C28.9651 22.0499 29.064 21.8113 29.064 21.5625C29.064 21.3138 28.9651 21.0752 28.7892 20.8993C28.6133 20.7233 28.3747 20.6245 28.1259 20.6245C27.8772 20.6245 27.6386 20.7233 27.4627 20.8993L19.0251 29.3368C18.938 29.4238 18.8688 29.5272 18.8216 29.641C18.7745 29.7548 18.7502 29.8768 18.7502 30C18.7502 30.1232 18.7745 30.2452 18.8216 30.359C18.8688 30.4728 18.938 30.5762 19.0251 30.6633Z"
        fill="#F4F7FF"
      />
      {/* </g> */}
    </svg>
  );
};
