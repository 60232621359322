import Header from "../Header";
import LaptopRepair from "./LaptopRepair";
import Advantages from "./Advantages";
import Services from "./Services";
import Professionalism from "./Professionalism";
import WorkProcess from "./WorkProcess";
import Team from "./Team";
import Footer from "../Footer";
import Discount from "./Discount";
import Questions from "./Questions";
import Brands from "./Brands";
import Contacts from "./Contacts";
import Reviews from "./Reviews";

const MainPage = () => {
  return (
    <>
      <Header isMainPage={true} />
      <LaptopRepair />
      <Advantages />
      <Services />
      <Professionalism />
      <div className="wrapperWorkProcess">
        <WorkProcess />
      </div>
      <Team />
      <div className="wrapperQuestions">
        <Questions />
      </div>
      <Discount />
      <Brands />
      <Reviews />
      <Contacts />
      <Footer />
    </>
  );
};

export default MainPage;
