import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar } from "swiper/modules";
import "swiper/scss";
import "swiper/scss/scrollbar";
import { dataProfessionalism } from "./constants/dataAccordion";

const Professionalism = () => {
  return (
    <section className="professionalism">
      <h1 className="professionalism__title desktop">ПРОФЕССИОНАЛИЗМ</h1>
      <h1 className="professionalism__title mobile">МЫ ПРОФИ</h1>
      <p className="professionalism__text">
        Мы сталкивались со всем, что может вас беспокоить: от жидкостей
        на клавиатуре до самых старых ноутбуков — и всегда находили решение.
        Вот лишь небольшой перечень наших навыков{" "}
        <Link className="link" to="/price">
          полный перечень здесь
        </Link>
      </p>
      <div className="professionalism__wrapperItems desktop">
        {dataProfessionalism.map((item, index) => {
          return (
            <div className="item" key={index}>
              <div className="svg">{item.svg}</div>
              <h3 className="title">{item.title}</h3>
              <p className="text">{item.text}</p>
            </div>
          );
        })}
      </div>
      <div className="mobile-slider-container">
        <div className="professionalism__wrapperItems">
          <Swiper
            // style={{ overflow: "unset" }}
            // spaceBetween={20}
            // centeredSlides={true}

            slidesPerView={1}
            scrollbar={{
              hide: false,
              // draggable: true,
            }}
            modules={[Scrollbar]}
          >
            {dataProfessionalism.map((item, index) => {
              return (
                <SwiperSlide>
                  <div className="item" key={index}>
                    <div className="svg">{item.svg}</div>
                    <h3 className="title">{item.title}</h3>
                    <p className="text">{item.text}</p>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </section>
  );
};
export default Professionalism;
