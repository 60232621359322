import Footer from "../Footer";
import Header from "../Header";
import Questions from "../MainPage/Questions";
import LeaveFeedback from "./LeaveFeedback";
import ReviewsSlider from "./ReviewsSlider";
import bacgroundImg from "../../assets/img/backgroundReviewsPage.jpg";

const ReviewsPage = () => {
  return (
    <>
      <Header isMainPage={false} />
      <div className="wrapperReviewsPage">
        <img
          className="wrapperReviewsPage__background"
          src={bacgroundImg}
          alt="laptop"
        ></img>
        <ReviewsSlider />
        <LeaveFeedback />
        <div className="wrapperQuestions">
          <Questions />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ReviewsPage;
