import Footer from "../Footer";
import Header from "../Header";
import Questions from "../MainPage/Questions";
import BeforeTheRepair from "./BeforeTheRepair";

const AdvicePage = () => {
  return (
    <>
      <Header isMainPage={false} />
      <BeforeTheRepair />
      <div className="wrapperQuestions">
        <Questions />
      </div>

      <Footer />
    </>
  );
};

export default AdvicePage;
