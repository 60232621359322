import { Button } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { Cross } from "../../../assets/svg/cross";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import Success from "../Success";
import sendEmail from "../sendEmail ";

const OrderService = ({ handleClose }) => {
  const validationSchema = Yup.object({
    name: Yup.string().required("Это поле обязательное для заполнения"),
    numberPhone: Yup.string().required("Это поле обязательное для заполнения"),
    checkbox: Yup.bool().oneOf(
      [true],
      "Пожалуйста,  примите наши условия, прежде чем продолжить."
    ),
  });

  const onSubmit = (values, { resetForm }) => {
    // Отправка данных на почту
    sendEmail(values);
    setValueNumber(values.numberPhone);

    // Сброс формы после отправки
    resetForm();
    handleOpen();
  };

  const [valueNumber, setValueNumber] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const styleBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  return (
    <div className="orderService">
      <div className="wrapperTitle">
        <h1 className="orderService__title">Заказать услугу</h1>
        <div className="orderService__buttonClose" onClick={handleClose}>
          <Cross />
        </div>
      </div>
      <Formik
        initialValues={{ name: "", numberPhone: "", checkbox: false }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ isValidating, isValid }) => (
          <Form className="orderService__form">
            <p className="text">
              Оставьте свои контактные данные и мы свяжемся с Вами в течение
              15 минут для уточнения деталей заказа.
            </p>

            <Field
              className="name"
              type="text"
              name="name"
              placeholder="Ваше имя"
            ></Field>
            <div className="wrapperError">
              <ErrorMessage name="name" component="p" className="error" />
            </div>

            <Field
              className="numberPhone"
              type="text"
              name="numberPhone"
              placeholder="Ваш номер телефона"
            ></Field>
            <div className="wrapperError">
              <ErrorMessage
                name="numberPhone"
                component="p"
                className="error"
              />
            </div>

            <div className="wrapperCheckboxButton">
              <div className="wrapperCheckAndError">
                <div className="wrapperCheckbox">
                  <Field name="checkbox">
                    {({ field }) => (
                      <Checkbox
                        {...field}
                        size="medium"
                        sx={{
                          color: "#4B67B9",
                          padding: "0",
                          "&.Mui-checked": {
                            color: "#4B67B9",
                          },
                        }}
                      />
                    )}
                  </Field>

                  <p className="wrapperCheckbox__text" for="checkbox-id">
                    Я согласен с правилами <br />
                    <Link className="wrapperCheckbox__link" to="/privacyPolicy">
                      обработки персональных данных
                    </Link>
                  </p>
                </div>
                <div className="wrapperError">
                  <ErrorMessage
                    name="checkbox"
                    component="p"
                    className="error"
                  />
                </div>
              </div>

              <Modal open={open} onClose={handleClose}>
                <Box sx={styleBox}>
                  <Success
                    handleClose={handleClose}
                    numberPhone={valueNumber}
                  />
                </Box>
              </Modal>
              <Button className="button" variant="contained" type="submit">
                Отправить
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default OrderService;
