export const IconQuestion = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1 12.5C1 18.5751 5.92487 23.5 12 23.5C18.0751 23.5 23 18.5751 23 12.5C23 6.42487 18.0751 1.5 12 1.5C5.92487 1.5 1 6.42487 1 12.5Z"
        fill="#4B67B9"
      />
      <path
        d="M10.245 15.1508V14.9729C10.2485 14.3624 10.3026 13.8757 10.4072 13.5128C10.5154 13.15 10.6724 12.857 10.8782 12.6337C11.084 12.4104 11.3317 12.2081 11.6213 12.0266C11.8376 11.8871 12.0312 11.7423 12.2022 11.5923C12.3731 11.4423 12.5092 11.2766 12.6104 11.0951C12.7115 10.9102 12.7621 10.7044 12.7621 10.4776C12.7621 10.2369 12.7046 10.0258 12.5894 9.84443C12.4743 9.66302 12.3191 9.52347 12.1237 9.42578C11.9318 9.3281 11.719 9.27926 11.4853 9.27926C11.2585 9.27926 11.0439 9.32984 10.8416 9.43102C10.6392 9.5287 10.4735 9.67523 10.3444 9.8706C10.2154 10.0625 10.1456 10.3015 10.1351 10.5875H8C8.01744 9.88979 8.1849 9.31415 8.50238 8.86061C8.81985 8.40358 9.24025 8.06343 9.76356 7.84015C10.2869 7.61338 10.8643 7.5 11.4957 7.5C12.19 7.5 12.804 7.61513 13.3378 7.84539C13.8716 8.07215 14.2902 8.40184 14.5937 8.83444C14.8972 9.26705 15.049 9.78861 15.049 10.3991C15.049 10.8073 14.981 11.1702 14.8449 11.4876C14.7123 11.8016 14.5257 12.0807 14.285 12.3249C14.0442 12.5657 13.7599 12.7837 13.432 12.9791C13.1564 13.143 12.9296 13.314 12.7517 13.4919C12.5772 13.6698 12.4464 13.8757 12.3592 14.1094C12.2755 14.3432 12.2318 14.631 12.2284 14.9729V15.1508H10.245ZM11.2812 18.5C10.9323 18.5 10.634 18.3779 10.3863 18.1337C10.1421 17.886 10.0217 17.5894 10.0252 17.2441C10.0217 16.9022 10.1421 16.6091 10.3863 16.3649C10.634 16.1207 10.9323 15.9986 11.2812 15.9986C11.6126 15.9986 11.9039 16.1207 12.1551 16.3649C12.4063 16.6091 12.5336 16.9022 12.5371 17.2441C12.5336 17.4743 12.4726 17.6854 12.3539 17.8773C12.2388 18.0657 12.0871 18.2174 11.8987 18.3325C11.7103 18.4442 11.5044 18.5 11.2812 18.5Z"
        fill="white"
      />
    </svg>
  );
};

export const IconCheck = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 11.5L9 16.5L20 5.5"
        stroke="#4B67B9"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export const IconError = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 13.5C12.5523 13.5 13 13.0523 13 12.5V8.5C13 7.94772 12.5523 7.5 12 7.5C11.4477 7.5 11 7.94772 11 8.5V12.5C11 13.0523 11.4477 13.5 12 13.5Z"
        fill="#FF2F36"
      />
      <path
        d="M12 17.5C11.4477 17.5 11 17.0523 11 16.5C11 15.9477 11.4477 15.5 12 15.5H12.01C12.5623 15.5 13.01 15.9477 13.01 16.5C13.01 17.0523 12.5623 17.5 12.01 17.5H12Z"
        fill="#FF2F36"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1 12.5C1 18.5751 5.92487 23.5 12 23.5C18.0751 23.5 23 18.5751 23 12.5C23 6.42487 18.0751 1.5 12 1.5C5.92487 1.5 1 6.42487 1 12.5ZM12 21.5C7.02944 21.5 3 17.4706 3 12.5C3 7.52944 7.02944 3.5 12 3.5C16.9706 3.5 21 7.52944 21 12.5C21 17.4706 16.9706 21.5 12 21.5Z"
        fill="#FF2F36"
      />
    </svg>
  );
};
