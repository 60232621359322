import photo1 from "../../../../assets/img/teamPhoto1.png";
import photo2 from "../../../../assets/img/teamPhoto2.png";
import photo3 from "../../../../assets/img/teamPhoto3.png";

export const dataTeam = [
  {
    photo: <img className="img" src={photo1} alt="person"></img>,
    name: "Алексей, Инженер-программист",
    text: "Здравствуйте, меня зовут Алексей, я по образованию инженер-программист, а с 2013 года — компьютерный мастер. Делаю свою работу качественно и аккуратно.",
  },
  {
    photo: <img className="img" src={photo2} alt="person"></img>,
    name: "Александр, Инженер",
    text: "Я Александр и я мастер по компонентному ремонту плат ноутбуков. Несу ответственность за восстановление вашей техники и её дальнейшую работоспособность.",
  },
  {
    photo: <img className="img" src={photo3} alt="person"></img>,
    name: "Константин, Инженер BGA",
    text: "Меня зовут Константин. Я эксперт в области микроэлектроники. Являюсь многопрофильным сервисным инженером. Осуществляю действительно ювелирную работу.",
  },
];
