import { Link } from "react-router-dom";
import { dataHeader } from "./constants/dataHeader";
import { BurgerButtonClose } from "../../assets/svg/headerburgerButtonMobile";

const BurgerMenu = ({ handleClose }) => {
  return (
    <div className="burgerMenu">
      <div className="burgerMenu__btnClose" onClick={handleClose}>
        <BurgerButtonClose />
      </div>
      <ul className="burgerMenu__list">
        {dataHeader.map((item, index) => {
          return (
            <Link key={index} className="item" to={item.link}>
              {item.name}
            </Link>
          );
        })}
      </ul>
    </div>
  );
};

export default BurgerMenu;
