export const dataWorkProcess = [
  {
    number: 1,
    title: "Оставляете заявку",
    text: "Позвоните нам 8 (916) 791-28-44 или оставьте заявку на сайте.",
  },
  {
    number: 2,
    title: "Консультация",
    text: "Мы озвучим возможные причины поломки и примерную стоимость.",
  },
  {
    number: 3,
    title: "Выезд",
    text: "Мастер бесплатно выезжает в удобное для вас время и место.",
  },
  {
    number: 4,
    title: "Диагностика",
    text: "Мастер диагностирует проблему. Сообщает стоимость и условия работы. ",
  },
  {
    number: 5,
    title: "Ремонт",
    text: "После вашего согласия мастер осуществляет ремонт. В 90% случаях ремонтируем за 30 минут.",
  },
  {
    number: 6,
    title: "Оплата",
    text: "Мастер принимает оплату: наличными или картой. Выдает гарантийный талон.",
  },
];
