export const BurgerButton = () => {
  return (
    <svg
      width="30"
      height="23"
      viewBox="0 0 30 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="6" y="0.303955" width="24" height="4" rx="1" fill="white" />
      <rect x="6" y="9.30396" width="24" height="4" rx="1" fill="white" />
      <path
        d="M0 18.304H30V20.304C30 21.4085 29.1046 22.304 28 22.304H2C0.89543 22.304 0 21.4085 0 20.304V18.304Z"
        fill="white"
      />
    </svg>
  );
};

export const BurgerButtonClose = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 24.0781L24 6.07812"
        stroke="#F4F7FF"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M23.918 24.1562L6.07573 5.99986"
        stroke="#F4F7FF"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};
