import { Cross } from "../../../assets/svg/cross";
import { Button } from "@mui/material";
import checkSuccess from "../../../assets/img/checkSuccess.jpg";

const ReviewsSent = ({ handleClose }) => {
  return (
    <div className="reviewsSent">
      <div className="reviewsSent__buttonClose" onClick={handleClose}>
        <Cross />
      </div>
      <div className="reviewsSent__img">
        <img src={checkSuccess} alt="Check Success"></img>
      </div>
      <h1 className="reviewsSent__title">Спасибо!</h1>
      <p className="reviewsSent__text">Ваш отзыв отправлен.</p>
      <Button
        className="buttonMobile"
        variant="contained"
        onClick={handleClose}
      >
        Хорошо
      </Button>
    </div>
  );
};
export default ReviewsSent;
