import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import laptopRepairImg from "../../../assets/img/laptopRepairImg.png";
import OrderService from "../../ModalWindows/OrderService";

const LaptopRepair = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const styleBox = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };
  return (
    <section className="laptopRepair">
      <div className="laptopRepair__background"></div>
      <div className="laptopRepair__text">
        <h1 className="title">
          Ремонт ноутбуков <br /> в Москве
        </h1>
        <p className="subtitle">Решим любые Ваши трудности с ноутбуком!</p>
        <Button
          className="orderButton"
          variant="contained"
          onClick={handleOpen}
        >
          Заказать ремонт
        </Button>
        <Modal open={open} onClose={handleClose}>
          <Box sx={styleBox}>
            <OrderService handleClose={handleClose} />
          </Box>
        </Modal>
        <Link to="/price">
          <Button className="knowButton" variant="contained">
            Узнать стоимость
          </Button>
        </Link>
      </div>
      <div className="laptopRepair__img">
        <img src={laptopRepairImg} alt="laptop"></img>
      </div>
    </section>
  );
};
export default LaptopRepair;
