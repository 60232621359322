import { NavLink, Link } from "react-router-dom";
import { Logo } from "../../assets/svg/logo";
import { PhoneIcon } from "../../assets/svg/phoneIcon";
import { useState } from "react";
import { BurgerButton } from "../../assets/svg/headerburgerButtonMobile";
import { dataHeader } from "./constants/dataHeader";
import BurgerMenu from "./burgerMenu";

const Header = ({ isMainPage }) => {
  const mainPageClass = isMainPage ? "mainPage" : "";
  const mainPageLogoClass = isMainPage ? "" : "logo";
  const mainPageMobileBurger = isMainPage ? "" : "burger";
  const mainPageNumberPhone = isMainPage ? "tablet" : "";

  const [isOpenBurger, setIsOpenBurger] = useState(false);

  const handleOpen = () => {
    setIsOpenBurger(true);
  };
  const handleClose = () => {
    setIsOpenBurger(false);
  };

  return (
    <header className="header">
      <div className={isOpenBurger ? "visible" : "invisible"}>
        <BurgerMenu handleClose={handleClose} />
      </div>
      <div className={`${mainPageLogoClass}`}>
        <Link to="/"><Logo /></Link>
      </div>

      <ul className="header__list desktop">
        {dataHeader.map((item, index) => {
          return (
            <NavLink
              key={index}
              className={({ isActive }) =>
                isActive
                  ? `header__item active ${mainPageClass}`
                  : `header__item ${mainPageClass}`
              }
              to={item.link}
            >
              {item.name}
            </NavLink>
          );
        })}
      </ul>
      <div className={`header__number ${mainPageNumberPhone}`}>
        <PhoneIcon />
        <p className="number">8 (916) 791-28-44</p>
      </div>
      <div
        className={`mobileBurgerBtn ${mainPageMobileBurger} ${mainPageClass}`}
        onClick={handleOpen}
      >
        <BurgerButton />
      </div>
    </header>
  );
};
export default Header;
