export const Logo = () => {
  return (
    <svg
      width="195"
      height="50"
      viewBox="0 0 195 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M77.6162 44.9827V2.9245C77.6162 1.69328 76.6234 0.696289 75.3984 0.696289H12.7246C11.4996 0.696289 10.5068 1.69328 10.5068 2.9245V44.9806H0.710938V47.8949C0.710938 48.6722 1.34313 49.3044 2.12041 49.3044H86.0337C86.811 49.3044 87.4411 48.6722 87.4411 47.8949V44.9827H77.6162ZM74.932 42.3399H13.0583V3.44683H74.932V42.3399Z"
        fill="url(#paint0_linear_677_5379)"
      />
      <path
        d="M88.543 40.7219V34.7438H89.0639L91.7968 39.4068H91.5235L94.2307 34.7438H94.7517V40.7219H94.1453V35.6918H94.2905L91.7968 39.9619H91.4979L88.9871 35.6918H89.1493V40.7219H88.543Z"
        fill="white"
      />
      <path
        d="M99.2685 36.8789C99.9744 36.8789 100.512 37.0383 100.883 37.3571C101.258 37.6703 101.446 38.1371 101.446 38.7577C101.446 39.4011 101.247 39.8907 100.848 40.2266C100.45 40.5568 99.8805 40.7219 99.1404 40.7219H96.7662V34.7438H97.3896V36.8789H99.2685ZM99.1233 40.2181C99.6755 40.2181 100.094 40.0957 100.379 39.8508C100.669 39.606 100.814 39.2473 100.814 38.7748C100.814 38.3022 100.672 37.9549 100.387 37.7329C100.103 37.5052 99.6812 37.3913 99.1233 37.3913H97.3896V40.2181H99.1233ZM102.48 40.7219V34.7438H103.103V40.7219H102.48Z"
        fill="white"
      />
      <path
        d="M107.35 40.7219V34.7438H109.835C110.467 34.7438 110.962 34.8748 111.321 35.1367C111.685 35.3929 111.867 35.7715 111.867 36.2725C111.867 36.7679 111.694 37.1436 111.346 37.3998C110.999 37.6503 110.544 37.7756 109.98 37.7756L110.125 37.5621C110.791 37.5621 111.298 37.693 111.645 37.9549C111.993 38.2168 112.166 38.6011 112.166 39.1079C112.166 39.6203 111.984 40.0188 111.62 40.3035C111.261 40.5825 110.72 40.7219 109.997 40.7219H107.35ZM107.973 40.2181H109.997C110.504 40.2181 110.888 40.1241 111.15 39.9362C111.412 39.7484 111.543 39.458 111.543 39.0652C111.543 38.678 111.412 38.3933 111.15 38.2111C110.888 38.0289 110.504 37.9379 109.997 37.9379H107.973V40.2181ZM107.973 37.4511H109.801C110.262 37.4511 110.615 37.36 110.86 37.1778C111.11 36.9899 111.235 36.7138 111.235 36.3494C111.235 35.985 111.11 35.7117 110.86 35.5295C110.615 35.3416 110.262 35.2477 109.801 35.2477H107.973V37.4511Z"
        fill="white"
      />
      <path
        d="M116.36 40.7732C115.91 40.7732 115.494 40.6992 115.113 40.5511C114.731 40.3974 114.401 40.1839 114.122 39.9106C113.843 39.6373 113.624 39.3157 113.465 38.9456C113.311 38.5755 113.234 38.1713 113.234 37.7329C113.234 37.2945 113.311 36.8903 113.465 36.5202C113.624 36.1501 113.843 35.8284 114.122 35.5552C114.407 35.2819 114.74 35.0712 115.121 34.9232C115.503 34.7695 115.918 34.6926 116.368 34.6926C116.801 34.6926 117.208 34.7666 117.589 34.9146C117.971 35.057 118.293 35.2733 118.555 35.5637L118.153 35.9651C117.908 35.7146 117.638 35.5352 117.342 35.427C117.046 35.3132 116.727 35.2562 116.385 35.2562C116.027 35.2562 115.694 35.3189 115.386 35.4441C115.079 35.5637 114.811 35.7373 114.583 35.9651C114.356 36.1871 114.176 36.449 114.045 36.7508C113.92 37.0468 113.857 37.3742 113.857 37.7329C113.857 38.0916 113.92 38.4218 114.045 38.7235C114.176 39.0196 114.356 39.2815 114.583 39.5092C114.811 39.7313 115.079 39.9049 115.386 40.0302C115.694 40.1498 116.027 40.2095 116.385 40.2095C116.727 40.2095 117.046 40.1526 117.342 40.0387C117.638 39.9249 117.908 39.7427 118.153 39.4922L118.555 39.8935C118.293 40.1839 117.971 40.4031 117.589 40.5511C117.208 40.6992 116.798 40.7732 116.36 40.7732Z"
        fill="white"
      />
      <path
        d="M120.423 37.4169H123.583V37.9549H120.423V37.4169ZM120.492 40.1754H124.087V40.7219H119.86V34.7438H123.959V35.2904H120.492V40.1754ZM122.678 34.0606C122.581 34.0606 122.496 34.0265 122.422 33.9581C122.348 33.8898 122.311 33.8016 122.311 33.6934C122.311 33.5852 122.348 33.497 122.422 33.4287C122.496 33.3603 122.581 33.3262 122.678 33.3262C122.78 33.3262 122.866 33.3603 122.934 33.4287C123.008 33.497 123.045 33.5852 123.045 33.6934C123.045 33.8016 123.008 33.8898 122.934 33.9581C122.866 34.0265 122.78 34.0606 122.678 34.0606ZM121.158 34.0606C121.055 34.0606 120.967 34.0265 120.893 33.9581C120.825 33.8898 120.791 33.8016 120.791 33.6934C120.791 33.5852 120.825 33.497 120.893 33.4287C120.967 33.3603 121.055 33.3262 121.158 33.3262C121.255 33.3262 121.34 33.3603 121.414 33.4287C121.488 33.497 121.525 33.5852 121.525 33.6934C121.525 33.8016 121.488 33.8898 121.414 33.9581C121.34 34.0265 121.255 34.0606 121.158 34.0606Z"
        fill="white"
      />
      <path
        d="M127.724 40.7219V34.7438H128.348V39.6971L132.174 34.7438H132.746V40.7219H132.122V35.7772L128.296 40.7219H127.724Z"
        fill="white"
      />
      <path
        d="M137.376 40.7732C136.927 40.7732 136.511 40.6992 136.13 40.5511C135.748 40.3974 135.418 40.1839 135.139 39.9106C134.86 39.6373 134.641 39.3157 134.481 38.9456C134.328 38.5755 134.251 38.1713 134.251 37.7329C134.251 37.2945 134.328 36.8903 134.481 36.5202C134.641 36.1501 134.86 35.8284 135.139 35.5552C135.424 35.2819 135.757 35.0712 136.138 34.9232C136.52 34.7695 136.935 34.6926 137.385 34.6926C137.818 34.6926 138.225 34.7666 138.606 34.9146C138.988 35.057 139.309 35.2733 139.571 35.5637L139.17 35.9651C138.925 35.7146 138.655 35.5352 138.359 35.427C138.063 35.3132 137.744 35.2562 137.402 35.2562C137.043 35.2562 136.71 35.3189 136.403 35.4441C136.095 35.5637 135.828 35.7373 135.6 35.9651C135.372 36.1871 135.193 36.449 135.062 36.7508C134.937 37.0468 134.874 37.3742 134.874 37.7329C134.874 38.0916 134.937 38.4218 135.062 38.7235C135.193 39.0196 135.372 39.2815 135.6 39.5092C135.828 39.7313 136.095 39.9049 136.403 40.0302C136.71 40.1498 137.043 40.2095 137.402 40.2095C137.744 40.2095 138.063 40.1526 138.359 40.0387C138.655 39.9249 138.925 39.7427 139.17 39.4922L139.571 39.8935C139.309 40.1839 138.988 40.4031 138.606 40.5511C138.225 40.6992 137.815 40.7732 137.376 40.7732Z"
        fill="white"
      />
      <path
        d="M140.876 40.7219V34.7438H145.907V40.7219H145.283V35.1196L145.445 35.2904H141.338L141.5 35.1196V40.7219H140.876Z"
        fill="white"
      />
      <path
        d="M147.924 40.7219V34.7438H150.161C150.668 34.7438 151.103 34.8264 151.468 34.9915C151.832 35.1509 152.111 35.3843 152.305 35.6918C152.504 35.9935 152.604 36.3608 152.604 36.7935C152.604 37.2148 152.504 37.5792 152.305 37.8866C152.111 38.1884 151.832 38.4218 151.468 38.5869C151.103 38.752 150.668 38.8346 150.161 38.8346H148.274L148.556 38.5357V40.7219H147.924ZM148.556 38.5869L148.274 38.2795H150.144C150.742 38.2795 151.195 38.1514 151.502 37.8952C151.815 37.6333 151.972 37.266 151.972 36.7935C151.972 36.3152 151.815 35.9452 151.502 35.6833C151.195 35.4214 150.742 35.2904 150.144 35.2904H148.274L148.556 34.9915V38.5869Z"
        fill="white"
      />
      <path
        d="M152.715 40.7219L155.448 34.7438H156.072L158.804 40.7219H158.138L155.627 35.1111H155.884L153.373 40.7219H152.715ZM153.791 39.1249L153.979 38.6125H157.455L157.643 39.1249H153.791Z"
        fill="white"
      />
      <path
        d="M159.95 40.7219V34.7438H162.435C163.067 34.7438 163.562 34.8748 163.921 35.1367C164.285 35.3929 164.468 35.7715 164.468 36.2725C164.468 36.7679 164.294 37.1436 163.947 37.3998C163.599 37.6503 163.144 37.7756 162.58 37.7756L162.725 37.5621C163.392 37.5621 163.898 37.693 164.246 37.9549C164.593 38.2168 164.767 38.6011 164.767 39.1079C164.767 39.6203 164.584 40.0188 164.22 40.3035C163.861 40.5825 163.32 40.7219 162.597 40.7219H159.95ZM160.573 40.2181H162.597C163.104 40.2181 163.488 40.1241 163.75 39.9362C164.012 39.7484 164.143 39.458 164.143 39.0652C164.143 38.678 164.012 38.3933 163.75 38.2111C163.488 38.0289 163.104 37.9379 162.597 37.9379H160.573V40.2181ZM160.573 37.4511H162.401C162.862 37.4511 163.215 37.36 163.46 37.1778C163.71 36.9899 163.836 36.7138 163.836 36.3494C163.836 35.985 163.71 35.7117 163.46 35.5295C163.215 35.3416 162.862 35.2477 162.401 35.2477H160.573V37.4511Z"
        fill="white"
      />
      <path
        d="M166.347 40.7219V34.7438H166.97V39.6971L170.796 34.7438H171.368V40.7219H170.745V35.7772L166.919 40.7219H166.347Z"
        fill="white"
      />
      <path
        d="M173.386 40.7219V34.7438H173.907L176.639 39.4068H176.366L179.073 34.7438H179.594V40.7219H178.988V35.6918H179.133L176.639 39.9619H176.341L173.83 35.6918H173.992V40.7219H173.386Z"
        fill="white"
      />
      <path
        d="M18.7998 31.9664V11.263H26.9679C28.5315 11.263 29.8659 11.5427 30.9711 12.1021C32.0831 12.6547 32.9289 13.4399 33.5085 14.4575C34.0948 15.4684 34.388 16.6579 34.388 18.026C34.388 19.4008 34.0915 20.5836 33.4984 21.5743C32.9053 22.5582 32.0461 23.3131 30.9206 23.8387C29.8018 24.3644 28.4472 24.6272 26.8567 24.6272H21.3877V21.1093H26.1491C26.9848 21.1093 27.6789 20.9947 28.2316 20.7656C28.7842 20.5364 29.1953 20.1927 29.4649 19.7344C29.7412 19.2762 29.8794 18.7067 29.8794 18.026C29.8794 17.3386 29.7412 16.759 29.4649 16.2872C29.1953 15.8155 28.7808 15.4583 28.2215 15.2157C27.6688 14.9663 26.9713 14.8416 26.1289 14.8416H23.177V31.9664H18.7998ZM29.9804 22.5448L35.126 31.9664H30.2938L25.2595 22.5448H29.9804Z"
        fill="white"
      />
      <path
        d="M37.4814 31.9664V11.263H51.4319V14.872H41.8586V19.8052H50.7142V23.4141H41.8586V28.3575H51.4723V31.9664H37.4814Z"
        fill="white"
      />
      <path
        d="M54.9119 11.263H60.3102L66.0117 25.1731H66.2543L71.9558 11.263H77.3541V31.9664H73.1083V18.491H72.9364L67.5786 31.8653H64.6874L59.3296 18.4405H59.1578V31.9664H54.9119V11.263Z"
        fill="white"
      />
      <path
        d="M99.9858 21.6147C99.9858 23.8724 99.5578 25.7931 98.7019 27.3769C97.8528 28.9607 96.6936 30.1704 95.2244 31.0061C93.762 31.835 92.1176 32.2495 90.2912 32.2495C88.4513 32.2495 86.8002 31.8316 85.3377 30.996C83.8753 30.1603 82.7195 28.9505 81.8703 27.3668C81.0212 25.783 80.5966 23.8657 80.5966 21.6147C80.5966 19.357 81.0212 17.4363 81.8703 15.8526C82.7195 14.2688 83.8753 13.0625 85.3377 12.2335C86.8002 11.3978 88.4513 10.98 90.2912 10.98C92.1176 10.98 93.762 11.3978 95.2244 12.2335C96.6936 13.0625 97.8528 14.2688 98.7019 15.8526C99.5578 17.4363 99.9858 19.357 99.9858 21.6147ZM95.5479 21.6147C95.5479 20.1523 95.3289 18.919 94.8908 17.9148C94.4595 16.9106 93.8496 16.1491 93.0611 15.6302C92.2726 15.1112 91.3493 14.8518 90.2912 14.8518C89.2331 14.8518 88.3098 15.1112 87.5213 15.6302C86.7328 16.1491 86.1195 16.9106 85.6814 17.9148C85.2501 18.919 85.0345 20.1523 85.0345 21.6147C85.0345 23.0772 85.2501 24.3105 85.6814 25.3146C86.1195 26.3188 86.7328 27.0804 87.5213 27.5993C88.3098 28.1182 89.2331 28.3777 90.2912 28.3777C91.3493 28.3777 92.2726 28.1182 93.0611 27.5993C93.8496 27.0804 94.4595 26.3188 94.8908 25.3146C95.3289 24.3105 95.5479 23.0772 95.5479 21.6147Z"
        fill="white"
      />
      <path
        d="M120.545 11.263V31.9664H116.764L107.757 18.9358H107.605V31.9664H103.228V11.263H107.07L116.006 24.2835H116.188V11.263H120.545Z"
        fill="white"
      />
      <path
        d="M143.187 21.6147C143.187 23.8724 142.759 25.7931 141.903 27.3769C141.054 28.9607 139.895 30.1704 138.426 31.0061C136.963 31.835 135.319 32.2495 133.492 32.2495C131.652 32.2495 130.001 31.8316 128.539 30.996C127.076 30.1603 125.921 28.9505 125.071 27.3668C124.222 25.783 123.798 23.8657 123.798 21.6147C123.798 19.357 124.222 17.4363 125.071 15.8526C125.921 14.2688 127.076 13.0625 128.539 12.2335C130.001 11.3978 131.652 10.98 133.492 10.98C135.319 10.98 136.963 11.3978 138.426 12.2335C139.895 13.0625 141.054 14.2688 141.903 15.8526C142.759 17.4363 143.187 19.357 143.187 21.6147ZM138.749 21.6147C138.749 20.1523 138.53 18.919 138.092 17.9148C137.661 16.9106 137.051 16.1491 136.262 15.6302C135.474 15.1112 134.55 14.8518 133.492 14.8518C132.434 14.8518 131.511 15.1112 130.722 15.6302C129.934 16.1491 129.321 16.9106 128.883 17.9148C128.451 18.919 128.236 20.1523 128.236 21.6147C128.236 23.0772 128.451 24.3105 128.883 25.3146C129.321 26.3188 129.934 27.0804 130.722 27.5993C131.511 28.1182 132.434 28.3777 133.492 28.3777C134.55 28.3777 135.474 28.1182 136.262 27.5993C137.051 27.0804 137.661 26.3188 138.092 25.3146C138.53 24.3105 138.749 23.0772 138.749 21.6147Z"
        fill="white"
      />
      <path
        d="M159.217 11.263H163.595V24.7081C163.595 26.2177 163.234 27.5386 162.513 28.6709C161.799 29.8031 160.798 30.6859 159.511 31.3194C158.223 31.9462 156.724 32.2596 155.012 32.2596C153.293 32.2596 151.791 31.9462 150.503 31.3194C149.216 30.6859 148.215 29.8031 147.501 28.6709C146.787 27.5386 146.429 26.2177 146.429 24.7081V11.263H150.807V24.3341C150.807 25.1226 150.979 25.8235 151.322 26.4368C151.673 27.05 152.165 27.5319 152.798 27.8824C153.432 28.2328 154.17 28.408 155.012 28.408C155.861 28.408 156.599 28.2328 157.226 27.8824C157.859 27.5319 158.348 27.05 158.692 26.4368C159.042 25.8235 159.217 25.1226 159.217 24.3341V11.263Z"
        fill="white"
      />
      <path
        d="M166.407 14.872V11.263H183.411V14.872H177.073V31.9664H172.746V14.872H166.407Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_677_5379"
          x1="75.1805"
          y1="41.282"
          x2="47.4747"
          y2="-10.418"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#BECEFF" />
          <stop offset="1" stop-color="#DDE6FF" stop-opacity="0.82" />
        </linearGradient>
      </defs>
    </svg>
  );
};
