import { Cross } from "../../../assets/svg/cross";
import { Button } from "@mui/material";
import checkSuccess from "../../../assets/img/checkSuccess.jpg";

const Success = ({ handleClose, numberPhone }) => {
  return (
    <div className="success">
      <div className="success__buttonClose" onClick={handleClose}>
        <Cross />
      </div>
      <div className="success__img">
        <img src={checkSuccess} alt="Check Success"></img>
      </div>
      <h1 className="success__title">Спасибо!</h1>
      <p className="success__text">
        {`Ваша заявка отправлена. Мы свяжемся с вами по телефону
        ${numberPhone} в течение 15 минут!`}
      </p>
      <Button
        className="buttonMobile"
        variant="contained"
        onClick={handleClose}
      >
        Хорошо
      </Button>
    </div>
  );
};
export default Success;
